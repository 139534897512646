// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from "axios";
import { fetchUserBookings } from './tourBookingAPI';

const baseURL = process.env.REACT_APP_API_URL;

export interface TourBookingState {
  loading: boolean;
  tourQuote: any;
  tourBookings: any[];
  user: any
  error: any;
}

const initialState: TourBookingState = {
  loading: false,
  user: null,
  tourQuote: null,
  tourBookings: [],
  error: null,
};

export const createTourBookingAsync = createAsyncThunk(
  'tourBooking/createTourBooking',
  async (tourBooking: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/tour-bookings`,
        tourBooking,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTourBookingAsync = createAsyncThunk(
  'tourBooking/updateTourBooking',
  async (tourBooking: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const bid = tourBooking.id;
      delete(tourBooking.id);
      const { data } = await axios.patch(
        `${baseURL}/tour-bookings/${bid}`,
        tourBooking,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loadTourBookingsAsync = createAsyncThunk(
  'tourBooking/fetchUserBookings',
  async (user) => {
    const response = await fetchUserBookings(user);
    return response.data;
  }
);

export const bookingSlice = createSlice({
  name: 'tourBooking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTourBookingsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadTourBookingsAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tourBookings = payload;
      })
      .addCase(loadTourBookingsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createTourBookingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTourBookingAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tourQuote = payload;
      })
      .addCase(createTourBookingAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateTourBookingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTourBookingAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tourQuote = payload;
      })
      .addCase(updateTourBookingAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectTourBooking = (state: RootState) => state.tourBooking.tourBookings;
export const selectTourBookingQuote = (state: RootState) => state.tourBooking.tourQuote;
export const selectTourBookingById = (state: RootState, tourBookingId: number) => state.tourBooking.tourBookings.find(tourBooking => tourBooking.id === tourBookingId);

export default bookingSlice.reducer;