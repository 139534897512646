// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

import unauth from '../../images/unauthorized.png';

const Unauthorized = () => {
  return (
    <>
      <div className='container p-2'>
        <div className='col-md-3 m-auto'>
          <img src={unauth} alt='Unauthorized' className='img-fluid' />
        </div>
        <h2 className='text-center col-md-6 m-auto h2'>You need to login to perform this action!</h2>
        <br />
        <h6 className='text-center'>
          <Link className='btn btn-lg btn-r' to='/login'>
            Go to Login
          </Link>
        </h6>
      </div>
    </>
  );
};

export default Unauthorized;
