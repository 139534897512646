// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import axios from "axios";
import { INewsletterSubsriber } from "../../interfaces/newsletter";

const baseURL = process.env.REACT_APP_API_URL;

export const newsletterSubscribe = async (subscriber: INewsletterSubsriber) => {
  try {
    const response = await axios.post(`${baseURL}/newsletter`, subscriber);
    return response.data;
  } catch(error) {
    throw new Error();
  }
}
