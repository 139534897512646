// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICreateUser, IUser, IUserInfo } from "../../interfaces/user";
import { getUsers, getUser } from "./userAPI";
import { RootState } from "../store";

export interface UserState {
  loading: boolean;
  userInfo: IUserInfo | null;
  users: IUserInfo[];
  error: string | null;
  success: boolean;
}

const initialState: UserState = {
  loading: false,
  userInfo: null,
  users: [],
  error: null,
  success: false,
}

export const getUserAsync = createAsyncThunk(
  'user/getUser',
  async (userId: number, { rejectWithValue }) => {
    try {
      const { data } = await getUser(userId);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUsersAsync = createAsyncThunk(
  'user/getUsers',
  async (user: any, { rejectWithValue }) => {
    try {
      const { data } = await getUsers();
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.userInfo = null
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(getUserAsync.fulfilled, (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.success = true
    })
    .addCase(getUserAsync.rejected, (state, { payload }) => {
      state.loading = false
      // state.error = payload
    })
    .addCase(getUsersAsync.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(getUsersAsync.fulfilled, (state, { payload }) => {
      state.loading = false
      state.users = payload
      state.success = true
    })
    .addCase(getUsersAsync.rejected, (state, { payload }) => {
      state.loading = false
      // state.error = payload
    });
  },
});

export const { clearUser } = userSlice.actions;

export const selectUserInfo = (state: RootState) => state.user.userInfo;

export default userSlice.reducer;
