// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, useFormikContext } from 'formik';
import zxcvbn from 'zxcvbn';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { registerUserAsync } from '../../../app/auth/authSlice';
import { ClipLoader } from 'react-spinners';

export const Signup = () => {
  const [errors, setErrors] = React.useState<string>('');
  const { loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const evaluatePasswordStrength = (password: string) => {
    const result = zxcvbn(password);
    return result.score;
  };

  const getPasswordStrengthMessage = (score: number) => {
    switch (score) {
      case 0:
        return 'Very Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Moderate';
      case 3:
        return 'Strong';
      case 4:
        return 'Very Strong';
      default:
        return '';
    }
  };

  const PasswordStrengthCheck = () => {
    const { values } = useFormikContext<{ username: string; password: string; firstname: string; lastname: string }>(); // Explicitly define the type
    return (
      <div className='password-strength'>
        {values.password && values.password.length < 8 && (
          <small className='text-danger'>Password must be at least 8 characters long.</small>
        )}
        <br />
        {values.password && (
          <div className='password-strength'>
            <small className={`password-strength-label strength-${evaluatePasswordStrength(values.password)}`}>
              Password Strength: {getPasswordStrengthMessage(evaluatePasswordStrength(values.password))}
            </small>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='signup-page py-4'>
      <div className='signup-container'>
        <h2 className='signup-heading'>Signup</h2>
        <br />
        <div className='col-md-6 mx-auto text-center mb-3'>
          <small className='text-center mx-auto text-danger'>{errors}</small>
        </div>
        <Formik
          initialValues={{
            username: '',
            password: '',
            phone: '',
            firstname: '',
            lastname: '',
          }}
          onSubmit={async (values: { username: string; password: string; phone: string; firstname: string; lastname: string }) => {
            const req: any = await dispatch(registerUserAsync(values));
            if (req.meta.requestStatus === 'rejected') {
              setErrors(req.payload);
            }
            if (req.meta.requestStatus === 'fulfilled') {
              return navigate('/verify-otp', { state: { email: values.username } });
            }
          }}
        >
          {({ values }) => (
            <Form className='signup-form py-4'>
              <div className='d-flex'>
                <div className='form-group mx-1'>
                  <label htmlFor='name'>Firstname</label>
                  <Field type='text' id='firstname' name='firstname' required />
                </div>
                <div className='form-group mx-1'>
                  <label htmlFor='name'>Lastname</label>
                  <Field type='text' id='lastname' name='lastname' required />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='username'>Email</label>
                <Field type='email' id='username' name='username' placeholder='Eg. abc@xyz.com' required />
              </div>
              <div className='form-group'>
                <label htmlFor='phone'>Phone</label>
                <Field type='phone' id='phone' name='phone' placeholder='Eg. 09xx-xxx-xxx / 07xx-xxx-xxx' maxLength={10} required />
              </div>
              <div className='form-group'>
                <label htmlFor='password'>Password</label>
                <Field type='password' id='password' name='password' required />
                <br />
                <PasswordStrengthCheck />
              </div>

              {loading ? (
                <>
                  <button type='submit' className='btn signup-btn mx-auto btn-r' disabled>
                    Signup
                  </button>
                  <ClipLoader color='#e81f29' size={30} />
                </>
              ) : (
                <button
                  type='submit'
                  className='btn signup-btn mx-auto btn-r'
                  disabled={values.password.length < 8 || evaluatePasswordStrength(values.password) < 3}
                >
                  Signup
                </button>
              )}
            </Form>
          )}
        </Formik>
        <br />
        <div className='sub-login mx-auto'>
          <span>
            Already have an Account?{' '}
            <Link to='/login' className='red-txt'>
              Login
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
