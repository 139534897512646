// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';

import a from '../../images/comesa-blue-png.png';
import b from '../../images/chaminuka.jpg';
import c from '../../images/IC-Logo.png';
import d from '../../images/taj.png';
import e from '../../images/protea-logo_rev.png';
import f from '../../images/sa-high-c.png';

export const Clients = () => {

  const clients = [
    {
      id: 1,
      name: 'COMESA',
      logo: a,
    },
    {
      id: 2,
      name: 'Chaminuka Lodge',
      logo: b,
    },
    {
      id: 3,
      name: 'Intercontinental Hotel',
      logo: c,
    },
    {
      id: 4,
      name: 'Taj Pamodzi Hotel',
      logo: d,
    },
    {
      id: 5,
      name: 'Protea Hotels',
      logo: e,
    },
    // {
    //   id: 6,
    //   name: 'Danish Embassy',
    //   logo: '',
    // },
    {
      id: 7,
      name: 'South African High Commission',
      logo: f,
    },
  ];

  return (
    <div className="clients-section">
      <h2 className="section-heading display-4">Our Esteemed Clients</h2>
      <div className="clients-container">
        {clients.map((client) => (
          <div className="client" key={client.id}>
            <img src={client.logo} alt={client.name} className="client-logo" />
          </div>
        ))}
      </div>
    </div>
  );
};
