// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

/**
 * Format date and time
 * @param {any} dateTime
 * @returns {object} formattedDate, formattedTime
 * @example
 * formatDateTime(new Date());
 * // returns { formattedDate: 'January 1, 2023', formattedTime: '12:00 AM' }
 * @example
 * formatDateTime('2023-01-01T00:00:00.000Z');
 * // returns { formattedDate: 'January 1, 2023', formattedTime: '12:00 AM' }
 */
export function formatDateTime(dateTime: any) {
  // Format options for date and time
  const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeFormatOptions = { hour: 'numeric', minute: 'numeric' };

  const formattedDate = dateTime.toLocaleDateString(undefined, dateFormatOptions);
  const formattedTime = dateTime.toLocaleTimeString(undefined, timeFormatOptions);

  return {
    formattedDate,
    formattedTime,
  };
}

// Usage example
const startDateTime = new Date();
const { formattedDate, formattedTime } = formatDateTime(startDateTime);
console.log(formattedDate, formattedTime); // January 1, 2023 12:00 AM
