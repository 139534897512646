// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from "axios";
import { fetchUserBookings } from './bookingAPI';

const baseURL = process.env.REACT_APP_API_URL;

export interface BookingState {
  loading: boolean;
  quote: any;
  bookings: any[];
  user: any
  error: any;
}

const initialState: BookingState = {
  loading: false,
  user: null,
  quote: null,
  bookings: [],
  error: null,
};

export const createBookingAsync = createAsyncThunk(
  'booking/createBooking',
  async (booking: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/bookings`,
        booking,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateBookingAsync = createAsyncThunk(
  'booking/updateBooking',
  async (booking: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const bid = booking.id;
      delete(booking.id);
      const { data } = await axios.patch(
        `${baseURL}/bookings/${bid}`,
        booking,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loadBookingsAsync = createAsyncThunk(
  'booking/fetchUserBookings',
  async (user) => {
    const response = await fetchUserBookings(user);
    return response.data;
  }
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadBookingsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadBookingsAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.bookings = payload;
      })
      .addCase(loadBookingsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBookingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBookingAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quote = payload;
      })
      .addCase(createBookingAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateBookingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBookingAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quote = payload;
      })
      .addCase(updateBookingAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectBooking = (state: RootState) => state.booking.bookings;
export const selectBookingQuote = (state: RootState) => state.booking.quote;
export const selectBookingById = (state: RootState, bookingId: number) => state.booking.bookings.find(booking => booking.id === bookingId);

export default bookingSlice.reducer;