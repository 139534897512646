// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FaCar,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUserFriends,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaArrowAltCircleLeft,
} from 'react-icons/fa';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loadVehicleTypesAsync, selectVehicleTypeById } from '../../../app/vehicle-type/vehicle-typeSlice';

import logo from '../../../images/logo.png';
import { ClipLoader } from 'react-spinners';

import { formatDateTime } from '../../../utils/datetimeFomatter';
import { BookingModalRental } from '../car-rental/BookingModalRental';
import { createBookingAsync } from '../../../app/booking/bookingSlice';
import jwtDecode from 'jwt-decode';
import { getUserAsync } from '../../../app/user/userSlice';
import { IDecodedUser } from '../../../interfaces/user';
import { getToken } from '../../../utils/localStorage';
import { RootState } from '../../../app/store';
import { createTokenAsync } from '../../../app/payment/paymentSlice';

const DPO_URL = process.env.REACT_APP_DPO_URL;

export const UserQuotePreview: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { userInfo } = useAppSelector((state) => state.user);

  const tk = getToken();
  let d: IDecodedUser;
  let uid = 0;
  if (tk !== null) {
    d = jwtDecode(tk);
    uid = d.sub;
  }

  // Find the booking with the corresponding bookingId
  const booking = userInfo?.bookings.find((rental: any) => rental.id === Number(id));

  let currency = '';

  if (booking?.selectedRate === 'ZMW') {
    currency = 'K';
  } else if (booking?.selectedRate === 'USD') {
    currency = '$';
  }

  useEffect(() => {
    dispatch(getUserAsync(uid));
  }, [dispatch, uid]);

  // const { booking } = useAppSelector((state) => state.booking);

  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [vehTyp, setVehTyp] = useState(0);

  const handleModalOpen = (v: any) => {
    setVehTyp(v);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setVehTyp(0);
  };

  const handleBookingSubmit = (values: any) => {
    setLoading(true);
    dispatch(createBookingAsync(values));
    setTimeout(() => {
      navigate('/booking-preview');
    }, 3000);
    setShowModal(false);
    setVehTyp(0);
  };

  const { paymentToken } = useAppSelector((state: RootState) => state.payment);

  const handlePay = async (id: number) => {
    try {
      const req = await dispatch(createTokenAsync(id));
      navigate('/processing');
      if (req.meta.requestStatus === 'fulfilled') {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        window.location.href = DPO_URL + req.payload?.transToken;
      }
    } catch (error) {
      // Handle any errors that might occur during the async operation
      console.error('Error while handling payment:', error);
    }
  };

  const { vehicleTypes } = useAppSelector((state: RootState) => state.vehicleType);

  useEffect(() => {
    dispatch(loadVehicleTypesAsync());
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [dispatch]);

  const r = parseInt(booking?.vehicleType);
  const createdDate = new Date(booking?.createdAt);
  const updatedDate = new Date(booking?.updatedAt);
  const { formattedDate, formattedTime } = formatDateTime(createdDate);
  const { formattedDate: formattedUpdatedDate, formattedTime: formattedUpdatedTime } = formatDateTime(updatedDate);

  const startDateTime = new Date(booking?.startDate);
  const endDateTime = new Date(booking?.endDate);
  const { formattedDate: startDate, formattedTime: startTime } = formatDateTime(startDateTime);
  const { formattedDate: endDate, formattedTime: endTime } = formatDateTime(endDateTime);

  const vt = useAppSelector((state) => selectVehicleTypeById(state, r));

  if (booking?.selectedRate === 'ZMW') {
    currency = 'K';
  } else if (booking?.selectedRate === 'USD') {
    currency = '$';
  }

  try {
    // If `id` is not present, show the message for no booking to show
    if (!id || booking === undefined) {
      return (
        <div className='col-md-5 text-center mx-auto py-4'>
          <h3 className='text-muted'>No Quote to show!</h3>
          <div className='text-center mx-auto py-3'>
            <Link to='/' className='btn btn-lg btn-r wiggle-btn'>
              Go Home
            </Link>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='col-md-5 text-center mx-auto py-4'>
          <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
          <h3 className='text-muted'>Fetching Quote...</h3>
        </div>
      );
    }

    return (
      <>
        <div className='top-back-cont m-4'>
          <Link to='/dashboard/quotations' className='btn btn-md btn-r'>
            {' '}
            <FaArrowAltCircleLeft /> Go Back
          </Link>
        </div>
        <div className='col-md-9 shadow mx-auto py-5 px-3'>
          <div id='invoiceholder col-md-8 mx-auto'>
            <div id='invoice' className='effect2'>
              <div className='row justify-content-between container mx-auto'>
                <div className='info col-md-4 py-2'>
                  <img src={logo} className='logo' alt='City Drive' />
                  <p>
                    reservations@citydriverentacar.com
                    <br />
                    +260 976 760 159
                  </p>
                </div>
                <div className='title col-md-6 py-2'>
                  <h1>Quotation #{booking?.bookingNumber}</h1>
                  <p>
                    {formattedDate === formattedUpdatedDate && formattedTime === formattedUpdatedTime ? (
                      <>
                        Issued: {formattedDate} at {formattedTime}
                        <br />
                        Status:{' '}
                        <span className='p-2'>
                          {booking?.payment.length === 0 ? (
                            <>{booking?.status}</>
                          ) : (
                            <>
                              {booking?.payment.length === 0 ? (
                                <>{booking?.status}</>
                              ) : (
                                <>
                                  {booking?.payment.find(
                                    (payment: { status: string }) => payment.status === 'Transaction Paid'
                                  ) ? (
                                    <>
                                      50% of the Amount Paid <br />{' '}
                                      <small>
                                        <i>(The other amount will be paid on vehicle pickup)</i>
                                      </small>
                                    </>
                                  ) : (
                                    <>{booking?.payment[0]?.status}</>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        Issued: {formattedDate} at {formattedTime}
                        <br />
                        Last Updated: {formattedUpdatedDate} at {formattedUpdatedTime}
                        <br />
                        Status:
                        {booking?.payment.length === 0 ? (
                          <>{booking?.status}</>
                        ) : (
                          <>
                            {booking?.payment.find(
                              (payment: { status: string }) => payment.status === 'Transaction Paid'
                            ) ? (
                              <>
                                50% Paid <small>The other 50% of the amount will be paid upon vehicle pickup.</small>
                              </>
                            ) : (
                              <>{booking?.payment[0]?.status}</>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className='row' id='invoice-mid'>
                <div className='info col-md-4'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title'>Client Details </h4>
                      <div className='card-text py-3'>
                        <div className='row'>
                          <div className='col'>
                            <FaUser /> {booking?.firstname} {booking?.lastname}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaEnvelope /> {booking?.email}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaPhone /> {booking?.phone}
                          </div>
                        </div>
                        <hr />
                        <div className='col'>
                          <FaMapMarkerAlt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id='project' className='col-md-8'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title'>Booking Information</h4>
                      <div className='card-text py-3'>
                        <div className='row'>
                          <div className='col'>
                            <FaCar /> <strong>Vehicle Type:</strong> {booking?.vehicleType.type || vt?.type}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaMapMarkerAlt /> <strong>Pick-up & Destination: </strong>
                            {booking?.pickUpTown === booking?.drivingToTown ? (
                              <>{booking?.pickUpTown} (Within town)</>
                            ) : (
                              <>
                                {booking?.pickUpTown} &mdash; {booking?.drivingToTown} {' | '}{' '}
                                {booking?.drivingToCountry === 'Zambia' ? (
                                  <small>
                                    <strong>Within {booking?.drivingToCountry}</strong>
                                  </small>
                                ) : (
                                  <small>
                                    <strong>To {booking?.drivingToCountry}</strong>
                                  </small>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaMapMarkerAlt /> <strong>Drop-off:</strong> {booking?.drivingToTown}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaCalendarAlt /> <strong>Start & End Date:</strong> {startDate} {startTime} &mdash;{' '}
                            {endDate} {endTime} ({booking?.duration} days)
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaCar /> <strong>Drive Mode:</strong> {booking?.driveMode}
                          </div>
                          <div className='col-6'>
                            <FaUser /> <strong>Driver:</strong>{' '}
                            {booking?.driveMode === 'Self-drive' ? 'Not Included' : 'Not Assigned'}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaUserFriends /> <strong>Adults:</strong> {booking?.numOfAdults}
                          </div>
                          <div className='col-6'>
                            <FaUserFriends /> <strong>Children:</strong> {booking?.numOfChildren}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id='invoice-bot'>
                <div id='table' className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr className='tabletitle'>
                        <td className='item'>
                          <h4>Service Item</h4>
                        </td>
                        <td className='rate'>
                          <h4>Rate</h4>
                        </td>
                        <td className='duration'>
                          <h4>Duration</h4>
                        </td>
                        <td className='rate'></td>
                        <td className='subtotal'>
                          <h4>Sub-total</h4>
                        </td>
                      </tr>
                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'>Car Rental</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {currency} {booking?.bookingRateAmount}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>{booking?.duration} days</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {currency} {parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration)}
                          </p>
                        </td>
                      </tr>
                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'>Driver Allowance</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {booking?.driveMode === 'Self-drive' ? (
                              <>&mdash;</>
                            ) : (
                              <>
                                {currency} {booking?.driverRateAmount}
                              </>
                            )}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {booking?.driveMode === 'Self-drive' ? <>&mdash;</> : <>{booking?.duration} days</>}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {booking?.driveMode === 'Self-drive' ? (
                              <>{currency} 0.00</>
                            ) : (
                              <>
                                {currency} {booking?.totalDriverAllowance}
                              </>
                            )}
                          </p>
                        </td>
                      </tr>

                      {booking?.fuelCost === null || booking?.fuelCost <= 0 ? (
                        ''
                      ) : (
                        <>
                          <tr className='service'>
                            <td className='tableitem'>
                              <p className='itemtext'>One Way Rental Cost</p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>
                                  {currency}{' '}
                                  {(
                                    parseFloat(booking?.fuelCost) +
                                    parseFloat(booking?.tollFees) +
                                    parseFloat(booking?.busFare) +
                                    parseFloat(booking?.driverFood) +
                                    parseFloat(booking?.driverLodging)
                                  ).toFixed(2)}
                                </>
                              </p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>&mdash;</>
                              </p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'></p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>
                                  {currency}{' '}
                                  {(
                                    parseFloat(booking?.fuelCost) +
                                    parseFloat(booking?.tollFees) +
                                    parseFloat(booking?.busFare) +
                                    parseFloat(booking?.driverFood) +
                                    parseFloat(booking?.driverLodging)
                                  ).toFixed(2)}
                                </>
                              </p>
                            </td>
                          </tr>
                        </>
                      )}

                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'></td>
                        <td className='tableitem'>
                          <h6>Discount Applied</h6>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            &mdash; {currency} {booking?.discount} (7%)
                          </p>
                        </td>
                      </tr>
                      <tr className='tabletitle'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='rate'>
                          <h4>Total</h4>
                        </td>
                        <td className='payment'>
                          <h4>
                            {currency}
                            {booking?.fuelCost !== null
                              ? (
                                  parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                  parseFloat(booking?.totalDriverAllowance) +
                                  parseFloat(booking?.tollFees) +
                                  parseFloat(booking?.busFare) +
                                  parseFloat(booking?.driverFood) +
                                  parseFloat(booking?.driverLodging) -
                                  parseFloat(booking?.discount)
                                ).toFixed(2)
                              : (
                                  parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                  parseFloat(booking?.totalDriverAllowance) -
                                  parseFloat(booking?.discount)
                                ).toFixed(2)}
                          </h4>
                        </td>
                      </tr>

                      {booking?.payment.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {booking?.payment.find(
                            (payment: { status: string }) => payment.status === 'Transaction Paid'
                          ) ? (
                            <>
                              <tr className='service'>
                                <td className='tableitem'>
                                  <p className='itemtext'></p>
                                </td>
                                <td className='tableitem'>
                                  <p className='itemtext'></p>
                                </td>
                                <td className='tableitem'></td>
                                <td className='tableitem'>
                                  <h6>
                                    Amount Paid{' '}
                                    <small>
                                      <i>(50%)</i>
                                    </small>
                                  </h6>
                                </td>
                                <td className='tableitem'>
                                  <p className='itemtext'>
                                    {currency}
                                    {booking?.fuelCost !== null
                                      ? (
                                          (parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                            parseFloat(booking?.totalDriverAllowance) +
                                            parseFloat(booking?.tollFees) +
                                            parseFloat(booking?.busFare) +
                                            parseFloat(booking?.driverFood) +
                                            parseFloat(booking?.driverLodging) -
                                            parseFloat(booking?.discount)) /
                                          2
                                        ).toFixed(2)
                                      : (
                                          (parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                            parseFloat(booking?.totalDriverAllowance) -
                                            parseFloat(booking?.discount)) /
                                          2
                                        ).toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {booking?.payment.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {booking?.payment.find(
                            (payment: { status: string }) => payment.status === 'Transaction Paid'
                          ) ? (
                            <>
                              <tr className='service'>
                                <td className='tableitem'>
                                  <p className='itemtext'></p>
                                </td>
                                <td className='tableitem'>
                                  <p className='itemtext'></p>
                                </td>
                                <td className='tableitem'></td>
                                <td className='tableitem'>
                                  <h6>
                                    Remaining Balance <br />{' '}
                                    <small>
                                      <i>(to be paid on vehicle pickup)</i>
                                    </small>
                                  </h6>
                                </td>
                                <td className='tableitem'>
                                  <p className='itemtext'>
                                    {currency}
                                    {booking?.fuelCost !== null
                                      ? (
                                          (parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                            parseFloat(booking?.totalDriverAllowance) +
                                            parseFloat(booking?.tollFees) +
                                            parseFloat(booking?.busFare) +
                                            parseFloat(booking?.driverFood) +
                                            parseFloat(booking?.driverLodging) -
                                            parseFloat(booking?.discount)) /
                                          2
                                        ).toFixed(2)
                                      : (
                                          (parseFloat(booking?.bookingRateAmount) * parseFloat(booking?.duration) +
                                            parseFloat(booking?.totalDriverAllowance) -
                                            parseFloat(booking?.discount)) /
                                          2
                                        ).toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* <div className='col-md-8 mx-auto text-center' id='legalcopy'>
                  <p className='legal red-txt' style={{ fontSize: '13px' }}>
                    {' '}
                    <sup>*</sup> You are required to pay at least 50% of the total amount as down payment for your
                    booking to be reserved.
                  </p>
                </div> */}

                <div className='row justify-content-between my-4'>
                  <div className='col-md-2 row justify-content-between'>
                    {/* <div className='col-md-2'>
                      <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-secondary'>
                        Cancel
                      </Link>
                    </div> */}

                    {/* <div className='col-md-2'>
                      <button className='btn btn-lg btn-secondary' onClick={() => handleModalOpen(vt?.id)}>
                        Edit
                      </button>
                    </div> */}
                  </div>

                  {/* <div className='col-md-3'>
                    <button className='btn btn-lg btn-r' onClick={() => handlePay(booking?.id)}>
                      Proceed to Pay
                    </button>
                  </div> */}
                </div>

                <hr />

                <div className='col-md-8 mx-auto text-center' id='legalcopy'>
                  <p className='legal'>
                    <strong>
                      Thank you for considering <span className='red-txt'>City Drive</span>!
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingModalRental vt={vehTyp} show={showModal} onHide={handleModalClose} onSubmit={handleBookingSubmit} />
      </>
    );
  } catch (error) {
    // Handle the error gracefully
    console.error('Error in QuotePreview:', error);
    // Return a fallback UI or an error message
    return (
      <>
        <div className='col-md-5 text-center mx-auto py-4'>
          <h4 className='text-muted'>Sorry! Something went wrong while generating your quote.</h4>
          <p className='text-muted'>It's not your fault.</p>
          <div className='text-center mx-auto py-3'>
            <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-r wiggle-btn'>
              Please try again
            </Link>
          </div>
        </div>
      </>
    );
  }
};
