// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

export function obfuscateEmail(email: string) {
  const atIndex = email.indexOf('@');
  const dotIndex = email.lastIndexOf('.');

  if (atIndex !== -1 && dotIndex !== -1) {
    const username = email.substring(0, atIndex);
    const domain = email.substring(atIndex, dotIndex);
    const obfuscatedUsername = username.substring(0, Math.min(3, username.length)) + '*'.repeat(Math.max(0, username.length - 3));
    const obfuscatedDomain = domain.substring(0, 1) + '*'.repeat(Math.max(0, domain.length - 1));
    return obfuscatedUsername + obfuscatedDomain + email.substring(dotIndex);
  }

  return email; // Return original email if the format is not as expected
};