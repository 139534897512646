// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';

import { TeamMember } from '../../interfaces/team-member';

import greg from '../../images/team/gregory.jpg';
import linda from '../../images/team/linda.jpg';
import henry from '../../images/team/henry.jpg';
import getrude from '../../images/team/getrude.jpg';
import joseph from '../../images/team/joseph.jpg';
import mwape from '../../images/team/mwape.jpg';
import standwa from '../../images/team/standwa.jpg';
import { Link } from 'react-router-dom';

export const Team: React.FC = () => {
  const teamMembers: TeamMember[] = [
    {
      name: 'Gregory Chama',
      position: 'CEO',
      description: '',
      imageUrl: greg,
    },
    {
      name: 'Linda Phiri',
      position: 'General Manager / Head of Car Rental',
      description: '',
      imageUrl: linda,
    },
    {
      name: 'Henry Nyirenda',
      position: 'Head of Logistics',
      description: '',
      imageUrl: henry,
    },
    {
      name: 'Getrude Mutemwa',
      position: 'Head of Domestic Operations',
      description: '',
      imageUrl: getrude,
    },
    {
      name: 'Joseph Nkhata',
      position: 'Head of Taxi & IT',
      description: '',
      imageUrl: joseph,
    },
    {
      name: 'Mwape Sokoni',
      position: 'Head of Courier',
      description: '',
      imageUrl: mwape,
    },
    {
      name: 'Sthandwa Nkosi',
      position: 'Head of International Operations',
      description: '',
      imageUrl: standwa,
    },
  ];

  return (
    <section className='team-section py-3 my-3'>
      <h2>Our Team</h2>
      <p className='col-md-6 mx-auto'>
        At City Drive, we believe in putting faces to a great name. Our dedicated team of professionals is here to
        ensure that you receive a quality and unique car rental experience. Get to know the individuals who make City
        Drive the best choice for your transportation needs.
      </p>
      <div className='team-members'>
        {teamMembers.map((member, index) => (
          <div className='team-member shadow shadow-h' key={index}>
            <img src={member.imageUrl} alt={member.name} className='member-image' />
            <h3>{member.name}</h3>
            <p>{member.position}</p>
            {/* <p>{member.description}</p> */}
          </div>
        ))}
      </div>
      <p className='col-md-8 mx-auto py-5 my-5'>
        Together, our team is committed to providing you with a memorable car rental experience. We pride ourselves on
        our professionalism, attention to detail, and dedication to customer satisfaction.
        <br />
        Choose City Drive for your next adventure and let us take care of your transportation needs.
        <div className='text-center mx-auto py-3'>
        <Link to='/contact' className='btn btn-lg btn-r'>
          Contact Us
        </Link>
      </div>
      </p>
      
    </section>
  );
};
