// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

// import camper from '../../images/camper1.jpg';
import lts from '../../images/lts-rental.jpg';
import courtesy from '../../images/courtesy-rental.jpg';

export const ProductsAndServices = () => {
  return (
    <>
      <section className='products-and-services py-5' id='productsAndServices'>
        <div className='container'>
          <h3 className='text-center red-txt display-4'>Products &amp; Services</h3>
          <p className='col-md-7 mx-auto text-center red-txt lead'>
            Our car rental services offer a wide selection of vehicles to suit every traveler's needs.
          </p>
          <div className='row py-4 g-3 mx-auto justify-content-evenly text-center'>
            <Link
              to='/car-rental/long-and-short-term'
              className='card col-md-5 bg-light text-light p-0 shadow shadow-h'
            >
              <img src={lts} className='card-img' alt='Long & Short Term Car Rental' />
              <div className='card-img-overlay py-2'>
                <h3 className='h3'>Long & Short Term Car Rental</h3>
                <div className='more-btn btn-r btn btn-lg mb-2'>Book A Vehicle</div>
              </div>
            </Link>
            <Link to='/car-rental/courtesy' className='card col-md-5 bg-light text-dark p-0 shadow shadow-h'>
              <img src={courtesy} className='card-img' alt='Tour Packages' />
              <div className='card-img-overlay py-2'>
                <h3 className='h2'>Courtesy Car Rental</h3>
                <div className='more-btn btn-r btn btn-lg mb-2'>Explore</div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
