// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchTourPackages } from './tourPackageAPI';


export interface TourPackageState {
  loading: boolean;
  tourPackages: any[];
}

const initialState: TourPackageState = {
  loading: false,
  tourPackages: [],
};

export const loadTourPackagesAsync = createAsyncThunk(
  'tourPackage/fetchTourPackages',
  async () => {
    const response = await fetchTourPackages();
    return response.data;
  }
);

export const tourPackageSlice = createSlice({
  name: 'tourPackage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTourPackagesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadTourPackagesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.tourPackages = action.payload;
      })
      .addCase(loadTourPackagesAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectTourPackage = (state: RootState) => state.tourPackage.tourPackages;
export const selectTourPackageById = (state: RootState, tourPackageId: number) => state.tourPackage.tourPackages.find(tourPackage => tourPackage.id === tourPackageId);

export default tourPackageSlice.reducer;