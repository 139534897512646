// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

import { MDBFooter, MDBContainer, MDBIcon, MDBCol, MDBRow, MDBBtn } from 'mdb-react-ui-kit';
import { Newsletter } from '../sections/Newsletter';

// import logo from '../../images/logo.png';
import mtn from '../../images/mtn-logo.png';
import airtel from '../../images/airtel.png';
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';

export const Footer = () => {
  let year = new Date().getFullYear();

  return (
    <>
      <MDBFooter className='footer' color='white'>
        <MDBContainer className='py-1'>
          <section className='py-1'>
            <MDBRow>
              <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-3'>
                <h5 className='text-uppercase fw-bold mb-4 h4'>City Drive Rent A Car</h5>
                <p className='lead'>
                  We provide innovative mobility solutions to individuals and companies enabling them move from one
                  point to the other in an efficient and cost effective manner.
                </p>
              </MDBCol>

              <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                <h6 className='text-uppercase h5'>We accept</h6>
                <div className='pay-wrapper text-uppercase h6'>
                  <i>
                    <small>For Your Convenience</small>
                  </i>
                  <div className='payment'>
                    <span className='payment-item'>
                      <img src={visa} width='65' height='60' alt='' className='pay-img' />
                    </span>
                    <span className='payment-item'>
                      <img src={mastercard} width='65' height='60' alt='' className='pay-img' />
                    </span>
                    <span className='payment-item'>
                      <img src={mtn} width='65' height='60' alt='' className='pay-img' />
                    </span>
                    <span className='payment-item'>
                      <img src={airtel} width='65' height='60' alt='' className='pay-img' />
                    </span>
                  </div>
                </div>
                {/* <div className='img-fluid col-md-3'>
                  <img src={payment} alt='Accepted Payment Methods' />
                </div> */}
              </MDBCol>

              <MDBCol lg='3' md='6' className='mb-1 mb-md-0'>
                <h6 className='text-uppercase'>Company</h6>

                <ul className='list-unstyled mb-0'>
                  <li>
                    <Link to='/about' className='text-white'>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to='/branches' className='text-white'>
                      Branches
                    </Link>
                  </li>
                  <li>
                    <Link to='/team' className='text-white'>
                      Team
                    </Link>
                  </li>
                  {/* <li>
                  <Link to='/careers' className='text-white'>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to='/news' className='text-white'>
                    News
                  </Link>
                </li> */}
                  <li>
                    <Link to='/faq' className='text-white'>
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to='/terms-of-service' className='text-white'>
                      Terms Of Service
                    </Link>
                  </li>
                  <li>
                    <Link to='/privacy-policy' className='text-white'>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </MDBCol>

              <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-1'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon color='light' icon='home' className='me-2' />
                  Plot 18B, Stand 3642, Mwaleshi Road,
                </p>
                <p>
                  <MDBIcon color='light' icon='home' className='me-2' />
                  Olympia Park, Lusaka, Zambia
                </p>
                <p>
                  <MDBIcon color='light' icon='envelope' className='me-3' />
                  reservations@citydriverentacar.com
                </p>
                <p>
                  <MDBIcon color='light' icon='phone' className='me-3' /> +260 976 760 159
                </p>
              </MDBCol>
            </MDBRow>
          </section>
          <hr />
          <section className='mx-auto px-1'>
            <Newsletter />
          </section>
        </MDBContainer>
      </MDBFooter>

      <div className='text-center p-3 red-txt' style={{ backgroundColor: '#fff' }}>
        &copy; 2009 &mdash; {year}{' '}
        <a className='red-txt' href='https://citydriverentacar.com'>
          City Drive Rent A Car.
        </a>{' '}
        All rights reserved.
      </div>
    </>
  );
};
