// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

  /**
   * Calculates the number of days between two dates, including the start date.
   * @param startDateTime The starting date and time in string format.
   * @param endDateTime The ending date and time in string format.
   * @returns The number of days between the start and end dates, inclusive of the start date.
   */
  export function getDaysBetweenDates(startDateTime: string, endDateTime: string): number {
    // Convert the start and end date strings into Date objects
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    // Set the start date to 12:00 AM
    start.setHours(0, 0, 0, 0);

    // Check if the end date is before or exactly 10:00 AM
    if (
      end.getHours() < 10 ||
      (end.getHours() === 10 &&
        end.getMinutes() === 0 &&
        end.getSeconds() === 0)
    ) {
      // If end date is before or exactly 10:00 AM, adjust it to the previous day at 10:00 AM
      end.setDate(end.getDate() - 1);
      end.setHours(10, 0, 0, 0);
    } else {
      // If end date is after 10:00 AM, adjust it to 10:00 AM of the following day
      end.setHours(10, 0, 0, 0);
      end.setDate(end.getDate());
    }

    // Calculate the time difference between the start and end dates in milliseconds
    const timeDifference = Math.abs(end.getTime() - start.getTime());

    // Calculate the number of days between the start and end dates, inclusive of the start date
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

