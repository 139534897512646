// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

// import loc from '../../images/drive-location.png';

interface BranchGroup {
  [country: string]: Array<{
    name: string;
    address: string;
    phone: string;
    email: string;
    country: string;
  }>;
}

export const Branches: React.FC = () => {
  const branches = [
    {
      name: 'Lusaka - Head Office',
      address: 'Plot 6075/1 Chisokone Road, Off Great East Road, Northmead, P.O Box 38132, Lusaka',
      phone: '+260 976 760 159',
      email: 'reservations@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Kitwe Office',
      address: 'Unit 4 Lyness House, Freedom way opposite ECL Mall',
      phone: '+260 963 809 003',
      email: 'kitwe.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Ndola Office',
      address: 'Plot F89 Vitanda Street Ndola, Near Zambia Breweries',
      phone: '+260 972 109 709',
      email: 'ndola.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Solwezi Office',
      address: 'Plot 2945-Kansashi Road, Kunzubo, Office Complex-Suite No 16',
      phone: '+260 968 400 796',
      email: 'solwezi.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Windhoek Office',
      address: 'Office No. 316A. Regus Business Centre, 3rd Floor Maerua Office Tower. Maerua Mall, Windhoek.',
      phone: '+264 812 704 053',
      email: 'infowindhoek@citydriverentacar.com',
      country: 'Namibia',
    },
    {
      name: 'Maputo Office',
      address: 'Rua do Parque, Nrº 19 Sommerschield 1, Maputo',
      phone: '+258 844 101 055',
      email: 'info.maputo@citydriverentacar.com',
      country: 'Mozambique',
    },
  ];

  const branchesByCountry = branches.reduce((acc, branch) => {
    if (!acc[branch.country]) {
      acc[branch.country] = [];
    }
    acc[branch.country].push(branch);
    return acc;
  }, {} as BranchGroup);

  return (
    <div className='container contact-page shadow py-4'>
      <div className='contact-cont'>
      <div className='contact-details col-md-8 px-3'>
          <h3 className='text-center py-3'>Contact Information</h3>
          <p className='page-description col-md-9 mx-auto'>Our presence extends to multiple countries, encompassing offices and branches in Zambia, Namibia, and Mozambique.</p>
          {Object.entries(branchesByCountry).map(([country, countryBranches]) => (
            <div key={country}>
              <h3 className='text-center py-3 my-3'>{country}</h3>
              <div className='row justify-content-center gap-4'>
                {countryBranches.map((branch) => (
                  <div className='branch col-md-5 shadow shadow-h' key={branch.name}>
                    <h5 className='text-center'>{branch.name}</h5>
                    <p>
                      <i className='fa-solid fa-location-dot'></i> {branch.address}
                    </p>
                    <p>
                      <i className='fa-solid fa-phone'></i> {branch.phone}
                    </p>
                    <p>
                      <i className='fa-solid fa-envelope'></i> {branch.email}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className='text-center mx-auto py-3'>
            <Link to='/contact' className='btn btn-lg btn-r wiggle-btn'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

