// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

export const setToken = (token: string) => {
  return localStorage.getItem('userToken') === null ? localStorage.setItem('userToken', token) : null;
};

export const getToken = () => {
  return localStorage.getItem('userToken') !== null ? localStorage.getItem('userToken') : null;
}

export const removeToken = () => {
  return localStorage.removeItem('userToken');
}
