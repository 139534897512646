import React from 'react';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

import crash from '../../../images/car-crash.png';

export const Courtesy = () => {
  return (
    <div className='courtesy-container col-md-8 mx-auto gap-3 py-3'>
      <h1 className='text-center display-4'>Courtesy Car Rental</h1>

      <div className='card my-2 shadow'>
        <div className='card-body'>
          <p className='lead'>
            <span className='red-txt'>City Drive</span> has partnered with various insurance companies to offer a
            courtesy car service. If your car is involved in an accident, simply request a courtesy vehicle through your
            insurer. We will coordinate with you to hand over the vehicle, and your insurer will take care of the car
            rental bill by paying directly to City Drive.
          </p>

          <p className='lead'>
            It is important to add courtesy rental coverage to your insurance policy. Make sure to arrange this with
            your insurance company when buying or renewing your insurance. A courtesy vehicle rental will come in handy
            in the event of an accident.
          </p>
        </div>
      </div>

      <div className='card my-2 shadow'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-6 py-5'>
              <img src={crash} alt='How It Works Illustration' className='img-fluid' />
            </div>
            <div className='col-lg-6 py-4'>
              <h2 className='text-center display-6 mb-4'>How It Works</h2>

              <ol className='lead mx-3'>
                <li>A misfortune happens to your vehicle.</li>
                <li>Contact your broker or insurance company to arrange a courtesy vehicle for you.</li>
                <li>
                  Your broker/insurance company will then contact City Drive to hand over a courtesy vehicle to you.
                </li>
                <li>
                  Once your car has been repaired, return the courtesy car and retrieve your own from the workshop.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className='card my-2 shadow'>
        <div className='card-body benefits'>
          <h2 className='text-center display-6 py-3'>Benefits of Courtesy Vehicle Rental</h2>
          <div className='row py-2 mx-auto justify-content-evenly text-center'>
            <div className='card col-md-3 py-3'>
              <div className='card-body px-1'>
                <h5 className='card-title red-txt text-center h4'>Ensures continuity</h5>
                <p className='card-text lead'>Rent a temporary car to stay mobile while your car is in the garage.</p>
              </div>
            </div>

            <div className='card col-md-3 py-3'>
              <div className='card-body px-1'>
                <h5 className='card-title red-txt text-center h4'>Convenient</h5>
                <p className='card-text lead'>Get a car right away by requesting it through your insurance company.</p>
              </div>
            </div>

            <div className='card col-md-3 py-3'>
              <div className='card-body px-1'>
                <h5 className='card-title red-txt text-center h4'>Value for your money</h5>
                <p className='card-text lead'>
                  Even without rental coverage, we offer great cars in good condition at 10% discount
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card my-2 shadow'>
        <div className='card-body discount'>
          <h2 className='text-center discount-title display-6'>
            Don't Have Rental Coverage? <br /> <span className='h3 red-txt'>Get Up to 10% Discount</span>
          </h2>
          <p className='discount-notice lead text-center'>
            If you don't have rental coverage, worry not. We still offer a courtesy vehicle at a discount.
          </p>
          <div className='discount-requirements'>
            <p className='requirements-title lead'>To qualify for the 10% discount, we require:</p>
            <ul className='mx-auto requirements-list lead'>
              <li>A copy of the police report of the accident involving your car.</li>
              <li>A copy of insurance for the vehicle involved in the accident.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='text-center mx-auto py-3'>
        <Link to='/contact' className='btn btn-lg btn-r wiggle-btn'>
          Contact Us
        </Link>
      </div>
    </div>
  );
};
