// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';

import { BookingModal } from '../pages/car-rental/BookingModal';

import { createBookingAsync } from '../../app/booking/bookingSlice';
import { ClipLoader } from 'react-spinners';


export const Slider = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [load, setLoading] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleBookingSubmit = (values: any) => {
    setLoading(true);
    dispatch(createBookingAsync(values));
    setTimeout(() => {
      navigate('/quote-preview');
    }, 3000);
    setShowModal(false);
  };

  if (load) {
    return (
      <div className='col-md-5 text-center mx-auto py-4'>
        <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
        <h3 className='text-muted'>Generating Quote...</h3>
      </div>
    );
  }

  return (
    <>
      <div className='main-cover slider-cont  mx-auto px-4'>
        <div className=''>
          <div className='main-content col-md-12'>
            <h2 className='h1'>
              Rent A Car Online 7% Off
              <br />
              Safe, Convinient &amp; Reliable
              <br />
            </h2>
            <h3 className='text-center'>Your low cost Car Rental Partner</h3>

          {
            load ? (
              <div className='row col-md-4'>
                <button className='btn btn-lg btn-r wiggle-btn' disabled>Loading...</button>
                <ClipLoader size={35} color='#e81f29' className='mx-auto red-txt' />
              </div>
            ) : (
              <>
              <div className="cta-h mx-auto d-flex gap-3">
              <div className='text-center mx-auto py-3'>
                <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-r'>Rent a Vehicle</Link>
              </div>
              </div>
            </>
            )}
          </div>
          <div className='main-image-container col-md-6 mx-auto'>
            {/* <div className='main-img shadow'>
              <img src={cover} className='img-fluid ' alt='City Drive' />
            </div> */}
          </div>
        </div>
      </div>
      <BookingModal
        show={showModal}
        onHide={handleModalClose}
        onSubmit={handleBookingSubmit}
      />
    </>
  );
};
