// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { changeUserPasswordAsync } from '../../../app/auth/authSlice';
import { getToken } from '../../../utils/localStorage';

export const ResetPassword = () => {
  const [errors, setErrors] = React.useState<string>('');

  const { loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = getToken();

  if (user !== null) {
    window.location.href = '/dashboard/dashboard';
  }

  return (
    <div className='login-page py-5'>
      <div className='login-container'>
        <h2 className='login-heading'>Reset Your Password</h2>
        <br />
        <div className='col-md-8 mx-auto text-center mb-3'>
          <small className='text-center mx-auto text-danger'>{errors}</small>
        </div>
        <Formik
          initialValues={{
            username: '',
            currentPassword: '',
            newPassword: '',
          }}
          onSubmit={async (values: { username: string; currentPassword: string, newPassword: string }) => {
            const req = await dispatch(changeUserPasswordAsync(values));
            // resetForm();
            if (req.meta.requestStatus === 'rejected') {
              setErrors(req.payload);
            }
            if (req.meta.requestStatus === 'fulfilled') {
              return navigate('/dashboard/dashboard'); // TODO: Go to login page instead onf dashboard
            }
          }}
        >
          <Form className='login-form'>
            <div className='form-group'>
              <label htmlFor='username'>Username</label>
              <Field type='email' id='username' name='username' placeholder='Eg. abc@xyz.com' required />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Current Password</label>
              <Field type='password' id='currentPassword' name='currentPassword' required />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>New Password</label>
              <Field type='password' id='newPassword' name='newPassword' required />
            </div>
            {loading ? (
              <>
                <button type='submit' className='btn login-btn mx-auto btn-r' disabled>
                  Login
                </button>
                <ClipLoader color='#e81f29' size={30} />
              </>
            ) : (
              <button type='submit' className='btn login-btn mx-auto btn-r'>
                Login
              </button>
            )}
          </Form>
        </Formik>
        <br />
        <div className='sub-login'>
          <span>
            Don't have an Account?{' '}
            <Link to='/signup' className='red-txt'>
              Sign Up
            </Link>
          </span>
          <br />
          <span>
            Forgot Password?{' '}
            <Link to='/reset-password' className='red-txt'>
              Reset
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
