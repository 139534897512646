// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import axios from "axios";
import { IEnquiry } from "../../interfaces/enquiry";

const baseURL = process.env.REACT_APP_API_URL;

export const createEnquiry = async (enquiry: IEnquiry) => {
  try {
    const response = await axios.post(`${baseURL}/enquiries`, enquiry);
    return response.data;
  } catch(error) {
    console.log('Error in API req: ', error);
  }
}
