// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchVehicleTypes } from './vehicle-typeAPI';


export interface VehicleTypeState {
  loading: boolean;
  vehicleTypes: any[];
}

const initialState: VehicleTypeState = {
  loading: false,
  vehicleTypes: [],
};

export const loadVehicleTypesAsync = createAsyncThunk(
  'vehicleType/fetchVehicleTypes',
  async () => {
    const response = await fetchVehicleTypes();
    return response.data;
  }
);

export const vehicleTypeSlice = createSlice({
  name: 'vehicleType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadVehicleTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadVehicleTypesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleTypes = action.payload;
      })
      .addCase(loadVehicleTypesAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectVehicleType = (state: RootState) => state.vehicleType.vehicleTypes;
export const selectVehicleTypeById = (state: RootState, vehicleTypeId: number) => state.vehicleType.vehicleTypes.find(vehicleType => vehicleType.id === vehicleTypeId);

export default vehicleTypeSlice.reducer;