// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from "axios";
import { ICreatePaymentToken } from '../../interfaces/payment';

const baseURL = process.env.REACT_APP_API_URL;
const DPO_URL = process.env.REACT_APP_DPO_URL;

export interface PaymentState {
  loading: boolean;
  paymentToken: ICreatePaymentToken;
  error: any;
}

const initialState: PaymentState = {
  loading: false,
  paymentToken: {
    transToken: '',
    transRef: '',
    bookingNumber: '',
    amount: '',
    status: '',
    id: 0
  },
  error: null,
};

export const createTokenAsync = createAsyncThunk(
  'payment/createToken',
  async (id: number, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/payments/create-token/${id}`,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const chargeTokenAsync = createAsyncThunk(
  'payment/chargeToken',
  async (token: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${DPO_URL}/${token}`,
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTokenAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTokenAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentToken = payload;
      })
      .addCase(createTokenAsync.rejected, (state) => {
        state.loading = false;
      });
      // .addCase(createBookingAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(createBookingAsync.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.quote = payload;
      // })
      // .addCase(createBookingAsync.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = payload;
      // })
      // .addCase(updateBookingAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(updateBookingAsync.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.quote = payload;
      // })
      // .addCase(updateBookingAsync.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = payload;
      // });
  },
});

export const selectPayment = (state: RootState) => state.booking.bookings;
export const selectPaymentQuote = (state: RootState) => state.booking.quote;
export const selectPaymentById = (state: RootState, bookingId: number) => state.booking.bookings.find(booking => booking.id === bookingId);

export default paymentSlice.reducer;