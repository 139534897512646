// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';

import { logout } from '../../../app/auth/authSlice';
import { clearUser } from '../../../app/user/userSlice';
import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import avatar from '../../../images/user.png';

export const UserProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user.userInfo);

  return (
    <div className="user-profile-page">
      <div className="user-profile-container">
        <h2 className="user-profile-heading">My Profile</h2>
        <div className="user-profile-info">
          <div className="profile-picture">
            <img src={avatar} alt="Profile" className="profile-image" />
          </div>
          <div className="user-details">
            <div className="detail-row">
              <span className="detail-label">Name:</span>
              <span className="detail-value">{user?.firstname} {user?.lastname}</span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Email:</span>
              <span className="detail-value">{user?.username}</span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Phone:</span>
              <span className="detail-value">{user?.phone}</span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Address:</span>
              <span className="detail-value"></span>
            </div>
          </div>
        </div>
        <button className="btn edit-profile-btn btn-r text-white disabled">Edit Profile</button>
      </div>
    </div>
  );
};
