// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const createBooking = (booking: any) => {
  return axios.post(`${baseURL}/bookings`, booking);
}

export const fetchUserBookings = (userId: any) => {
  return axios.get(`${baseURL}/bookings`, {
    headers: {
      // Include the access token for authentication
      // Authorization: `Bearer ${accessToken}`,
    },
    params: {
      userId: userId, // Pass the userId as a query parameter
    },
  });
}

export const updateUserBooking = (id: number, booking: any) => {
  return axios.patch(`${baseURL}/bookings/${id}`, booking);
}
