// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

export const Overview = () => {
  return (
    <>
      <section className='overview py-5' id='overview'>
        <div className='overview-cont container mx-auto'>
          <h3 className='text-center display-4'>Why Choose City Drive?</h3>
          <p className='text-center text-white lead'>
            At City Drive Every Mile is a Memory: Rent a Car, Embark on Epic Tours!
          </p>
          <div className='row py-2 mx-auto justify-content-evenly text-center'>
            <div className='card ov-card col-md-2 shadow'>
              <div className='icon-cont mx-auto my-3'>
                <i className='fa-solid fa-headset fa-5x'></i>
              </div>
              <div className='card-body px-2'>
                <h5 className='card-title red-txt text-center'>Safety</h5>
                <p className='card-text lead'>New well mantained vehicles to avoid break downs</p>
              </div>
            </div>

            <div className='card ov-card col-md-2 shadow'>
              <div className='icon-cont mx-auto my-3'>
                <i className='fa-regular fa-circle-check fa-5x'></i>
              </div>
              <div className='card-body px-2'>
                <h5 className='card-title red-txt text-center'>Reliability</h5>
                <p className='card-text'>24 Hours Road side Assistance</p>
              </div>
            </div>

            <div className='card ov-card col-md-2 shadow'>
              <div className='icon-cont mx-auto my-3'>
                <i className='fa-solid fa-gauge-high fa-5x'></i>
              </div>
              <div className='card-body px-2'>
                <h5 className='card-title red-txt text-center'>Unlimited Mileage</h5>
                <p className='card-text'>No worries of paying for extra KMS</p>
              </div>
            </div>

            <div className='card ov-card col-md-2 shadow'>
              <div className='icon-cont mx-auto my-3'>
                <i className='fa-solid fa-car-burst fa-5x'></i>
              </div>
              <div className='card-body px-2'>
                <h5 className='card-title red-txt text-center'>Insurance</h5>
                <p className='card-text'>Your rental is covered all the way</p>
              </div>
            </div>

            <div className='card ov-card col-md-2 shadow'>
              <div className='icon-cont mx-auto my-3'>
                <i className='fa-solid fa-coins fa-5x'></i>
              </div>
              <div className='card-body px-2'>
                <h5 className='card-title red-txt text-center'>Low Cost</h5>
                <p className='card-text'>Will offer you great cars at best prices</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
