// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { MDBInput, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { INewsletterSubsriber } from '../../interfaces/newsletter';

import { newsletterSubscribe } from '../../app/newsletter/newsletterAPI';

export const Newsletter = () => {
  const navigate = useNavigate();

  const initialValues: INewsletterSubsriber = {
    name: '',
    email: '',
  };

  const handleSubmit = (values: INewsletterSubsriber, actions: FormikHelpers<INewsletterSubsriber>) => {
    newsletterSubscribe(values);
    actions.setSubmitting(false);
    actions.resetForm();
    navigate('/submit-success');
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-7 newsletter-sub'>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <MDBRow className='d-flex justify-content-start'>
                <MDBCol md='23'>
                  <p className='pt-2'>
                    <strong>Get every new update delivered to your inbox</strong>
                  </p>
                </MDBCol>

                <MDBCol md='4' start>
                  <Field className='mb-4' name='name' type='text' label='Your name' as={MDBInput} contrast required />
                </MDBCol>

                <MDBCol md='4'>
                  <Field
                    className='mb-4'
                    name='email'
                    type='email'
                    label='Email address'
                    as={MDBInput}
                    contrast
                    required
                  />
                </MDBCol>

                <MDBCol size='auto'>
                  <MDBBtn outline type='submit' className='btn-w py-1 px-2 mb-4 red-txt'>
                    Subscribe
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Form>
          </Formik>
        </div>
        <div className='col-md-3 social-btns py-4'>
          <div className='text-center mb-1'>
            <MDBBtn
              outline
              color='light'
              floating
              className='m-1'
              href='https://www.facebook.com/CITYDRIVERENTACAR/'
              role='button'
            >
              <MDBIcon className='text-white' fab icon='facebook-f' />
            </MDBBtn>

            <MDBBtn
              outline
              color='light'
              floating
              className='m-1'
              href='https://x.com/CityDrive4You'
              role='button'
            >
              <MDBIcon className='text-white' fab icon='x' />
            </MDBBtn>

            <MDBBtn
              outline
              color='light'
              floating
              className='m-1'
              href='https://instagram.com/city_drive'
              role='button'
            >
              <MDBIcon className='text-white' fab icon='instagram' />
            </MDBBtn>

            <MDBBtn
              outline
              color='light'
              floating
              className='m-1'
              href='https://www.linkedin.com/company-beta/17925970/'
              role='button'
            >
              <MDBIcon className='text-white' fab icon='linkedin-in' />
            </MDBBtn>
          </div>
        </div>
      </div>
    </>
  );
};
