// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, FormikHelpers, FieldInputProps } from 'formik';
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';

import { IEnquiry } from '../../interfaces/enquiry';
import { createEnquiry } from '../../app/enquiry/enquiryAPI';

interface BranchGroup {
  [country: string]: Array<{
    name: string;
    address: string;
    phone: string;
    email: string;
    country: string;
  }>;
}

export const Contact: React.FC = () => {
  const branches = [
    {
      name: 'Lusaka - Head Office',
      address: 'Plot 18B, Stand 3642, Mwaleshi Road, Olympia Park',
      phone: '+260 976 760 159',
      email: 'reservations@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Kitwe Office',
      address: 'Unit 4 Lyness House, Freedom way opposite ECL Mall',
      phone: '+260 963 809 003',
      email: 'kitwe.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Ndola Office',
      address: 'Plot F89 Vitanda Street Ndola, Near Zambia Breweries',
      phone: '+260 972 109 709',
      email: 'ndola.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Solwezi Office',
      address: 'Plot 2945-Kansashi Road, Kunzubo, Office Complex-Suite No 16',
      phone: '+260 968 400 796',
      email: 'solwezi.sales@citydriverentacar.com',
      country: 'Zambia',
    },
    {
      name: 'Windhoek Office',
      address: 'Office No. 316A. Regus Business Centre, 3rd Floor Maerua Office Tower. Maerua Mall, Windhoek.',
      phone: '+264 812 704 053',
      email: 'infowindhoek@citydriverentacar.com',
      country: 'Namibia',
    },
    {
      name: 'Maputo Office',
      address: 'Rua do Parque, Nrº 19 Sommerschield 1, Maputo',
      phone: '+258 844 101 055',
      email: 'info.maputo@citydriverentacar.com',
      country: 'Mozambique',
    },
  ];

  const branchesByCountry = branches.reduce((acc, branch) => {
    if (!acc[branch.country]) {
      acc[branch.country] = [];
    }
    acc[branch.country].push(branch);
    return acc;
  }, {} as BranchGroup);

  const navigate = useNavigate()

  const initialValues: IEnquiry = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    message: '',
  };

  const handleSubmit = (values: IEnquiry, actions: FormikHelpers<IEnquiry>) => {
    createEnquiry(values);
    actions.setSubmitting(false);
    actions.resetForm();
    navigate('/submit-success');
  };

  return (
    <div className='container-fluid contact-page shadow'>
      <div className='contact-cont'>
      <div className='contact-details col-md-6 px-3'>
          <h3 className='text-center py-3'>Contact Information</h3>
          <p className='page-description'>Our presence extends to multiple countries, encompassing offices and branches in Zambia, Namibia, and Mozambique.</p>
          {Object.entries(branchesByCountry).map(([country, countryBranches]) => (
            <div key={country}>
              <h3 className='text-center py-3 my-3'>{country}</h3>
              <div className='row justify-content-center gap-4'>
                {countryBranches.map((branch) => (
                  <div className='branch col-md-5 shadow shadow-h' key={branch.name}>
                    <h5 className='text-center'>{branch.name}</h5>
                    <p>
                      <i className='fa-solid fa-location-dot'></i> {branch.address}
                    </p>
                    <p>
                      <i className='fa-solid fa-phone'></i> {branch.phone}
                    </p>
                    <p>
                      <i className='fa-solid fa-envelope'></i> {branch.email}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className='contact-form-cont col-md-6 px-3'>
          <h3 className='text-center py-4'>Contact Us</h3>
          <p className='page-description'>
            For enquiries please fill out the form and we will get in touch with you shortly.
          </p>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form className='contact-form py-3 px-4 shadow'>
              <MDBRow className=''>
                <MDBCol>
                  <Field className='form-group' name='firstname' type='text' label='First name' as={MDBInput} outline required />
                </MDBCol>
                <MDBCol>
                  <Field className='form-group' name='lastname' type='text' label='Last name' as={MDBInput} outline required />
                </MDBCol>
              </MDBRow>
              <Field className='form-group' name='phone' type='text' label='Phone' as={MDBInput} outline required />
              <Field className='form-group' name='email' type='email' label='Email' as={MDBInput} outline required />
              <Field
                className='form-group'
                name='message'
                render={({ field }: { field: FieldInputProps<any> }) => (
                  <div className='form-group'>
                    <label htmlFor='message' className='form-label'>
                      Message / Enquiry
                    </label>
                    <textarea {...field} id='message' className='form-control' rows={5} placeholder='How can we help you?' required />
                  </div>
                )}
              />
              <MDBBtn className='my-3 btn-r' type='submit'>
                Submit
              </MDBBtn>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
