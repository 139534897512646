// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

// import { Counter } from "../../features/counter/Counter";
import { About } from "../sections/About";
// import { Carousel } from "../sections/Carousel";
import { Clients } from "../sections/Clients";
import { Contact } from "../sections/Contact";
// import { Newsletter } from "../sections/Newsletter";
import { Overview } from "../sections/Overview";
import { ProductsAndServices } from "../sections/ProductsAndServices";
// import { Services } from "../sections/Services";
import { Slider } from "../sections/Slider";

export const Home = () => {
  return (
    <>

      {/* <Carousel /> */}
      <Slider />
      <Overview />
      <ProductsAndServices />
      {/* <Services /> */}
      <About />
      <Contact />

      <Clients />
      {/* <Newsletter /> */}

      {/* Add a vehicle category section */}

  

    </>
  );
};