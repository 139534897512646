// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { getUserAsync } from '../../../app/user/userSlice';

import jwtDecode from 'jwt-decode';
import { IDecodedUser } from '../../../interfaces/user';
import { getToken } from '../../../utils/localStorage';

export const Overview = () => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state: RootState) => state.user);

  const tk = getToken();
  let d: IDecodedUser;
  let uid = 0;
  if (tk !== null) {
    d = jwtDecode(tk);
    uid = d.sub;
  }

  useEffect(() => {
    dispatch(getUserAsync(uid));
  }, [dispatch, uid]);

  return (
    <>
      <h2 className='user-dashboard-heading mb-2'>Hello, {userInfo?.firstname}!</h2>
      <h5 className='text-center'>Welcome to your profile.</h5>
      <br />
      <p className='text-center'>Here you can manage your profile, car rentals.</p>
      <br />
      <hr />
      <div className='user-dashboard-menu d-flex'>
        <Link to='/dashboard/quotations' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-file'></i>
          <span>Quotations</span>
        </Link>
        <Link to='/dashboard/rentals' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-car'></i>
          <span>Rentals Bookings</span>
        </Link>
        <Link to='/dashboard/accessories' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-cog'></i>
          <span>Accessories</span>
        </Link>
        <Link to='/dashboard/other-services' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-map-marked-alt'></i>
          <span>Other Services</span>
        </Link>
        <Link to='/dashboard/profile' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-user'></i>
          <span>Profile</span>
        </Link>
        <Link to='/contact' className='dashboard-menu-item shadow shadow-h p-5'>
          <i className='fas fa-question-circle'></i>
          <span>Help</span>
        </Link>
      </div>
    </>
  );
};
