import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import { Navbar } from './components/partials/Navbar';
import { Home } from './components/pages/Home';
import { Footer } from './components/partials/Footer';
import { About } from './components/pages/About';
import { Contact } from './components/pages/Contact';
import { CarRental } from './components/pages/car-rental/CarRental';
import { CarDetail } from './components/pages/car-rental/CarDetail';
import { Login } from './components/pages/auth/Login';
import { Signup } from './components/pages/auth/SignUp';
import { Dashboard } from './components/pages/user/Dashboard';
import { Testimonials } from './components/pages/Testimonials';
import { Faq } from './components/pages/Faq';
import { Team } from './components/sections/Team';
import { SubmissionSuccess } from './components/pages/SubmissionSucess';
import { Branches } from './components/pages/Branches';
import { Courtesy } from './components/pages/courtesy-rental/Courtesy';
import Protected from './components/guard/Protected';
import Unauthorized from './components/guard/Unauthorized';
import { RentalDetails } from './components/pages/user/RentalDetails';
import { QuotePreview } from './components/pages/car-rental/QuotePreview';
import { UserQuotePreview } from './components/pages/user/UserQuotePreview';
import { PrivacyPolicy } from './components/pages/PrivacyPolicy';
import { TermsOfService } from './components/pages/TermsOfService';
import { Processing } from './components/pages/Processing';
import { InstantLogin } from './components/pages/auth/InstantLogin';
import { ResetPassword } from './components/pages/auth/ResetPassword';
import { VerifyOtp } from './components/pages/auth/VerifyOtp';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/car-rental/long-and-short-term' element={<CarRental />} />
          <Route path='/car-rental/courtesy' element={<Courtesy />} />
          <Route path='/car-rental/:id' element={<CarDetail />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/team' element={<Team />} />
          <Route path='/branches' element={<Branches />} />

          <Route path='/submit-success' element={<SubmissionSuccess />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />

          <Route path='/quote-preview' element={<QuotePreview />} />
          <Route path='/processing' element={<Processing />} />

          <Route element={<Protected allowedRole='std' />}>
            <Route path='/dashboard/:section' element={<Dashboard />} />
            <Route path='/dashboard/quotations/:id' element={<UserQuotePreview />} />
            <Route path='/dashboard/rentals/:id' element={<RentalDetails />} />
          </Route>

          <Route path='/login' element={<Login />} />
          <Route path='/instant-login' element={<InstantLogin />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/verify-otp' element={<VerifyOtp />} />

          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
