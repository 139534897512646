// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

/**
 * Converts a date from the input string to the CAT (Central Africa Time) timezone.
 * CAT is UTC+2.
 * 
 * @param dateStr The input date string to be converted. Should be in a format that the Date constructor can understand.
 * @returns A string representing the converted date in the format "YYYY-MM-DDTHH:mm".
 */
export function convertDate(dateStr: string): string {
  // Create a new Date object from the input date string
  const date = new Date(dateStr);
  
  // Convert the date to CAT timezone (UTC+2)
  date.setUTCHours(date.getUTCHours() + 2);
  
  // Extract year, month, day, hours, and minutes from the converted date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Return the date and time in the "YYYY-MM-DDTHH:mm" format
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
