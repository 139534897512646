// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { logout } from '../../app/auth/authSlice';
import { clearUser } from '../../app/user/userSlice';
import { RootState } from '../../app/store';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import logo from '../../images/logo.png';
import avatar from '../../images/user.png';

export const Navbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user.userInfo);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearUser());
    navigate('/login', { replace: true });
    window.location.reload();
  };


  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  const closeNavOnLinkClick = () => {
    setIsNavOpen(false);
  };


  useEffect(() => {
  }, [dispatch]);


  return (
    <>
      <nav className={`navbar navbar-expand-lg bg-light shadow fixed-top px-3 py-3 ${isNavOpen ? 'show' : ''}`}>
        <div className='container-fluid'>
          <Link className='navbar-brand' to='/'>
            <img src={logo} alt='' width='150' height='45' className='d-inline-block align-text-top' />
          </Link>
          <button
          className='navbar-toggler'
          type='button'
          onClick={handleToggleNav}
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>


        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id='navbarNav'>
          <ul className='navbar-nav mx-auto'>
            <li className='nav-item'>
              <NavLink
                className='nav-link'
                to='/'
                onClick={closeNavOnLinkClick}
              >
                Home
              </NavLink>
            </li>
            <li className='nav-item dropdown'>
                <NavLink
                  className='nav-link dropdown-toggle'
                  to='#'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Products &amp; Services
                </NavLink>
                <ul className='dropdown-menu'>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/car-rental/long-and-short-term' onClick={closeNavOnLinkClick}>
                      Long &amp; Short Term Car Rental
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/car-rental/courtesy' onClick={closeNavOnLinkClick}>
                      Courtesy Car Rental
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <NavLink
                  className='nav-link dropdown-toggle'
                  to='#'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Company
                </NavLink>
                <ul className='dropdown-menu'>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/about' onClick={closeNavOnLinkClick}>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/team' onClick={closeNavOnLinkClick}>
                      Team
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className='dropdown-item red-txt' to='/news' onClick={closeNavOnLinkClick}>
                      News
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/branches' onClick={closeNavOnLinkClick}>
                      Our Branches
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/faq' onClick={closeNavOnLinkClick}>
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className='dropdown-item red-txt' to='/terms-of-service' onClick={closeNavOnLinkClick}>
                      Terms Of Service
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className='dropdown-item red-txt' to='/careers' onClick={closeNavOnLinkClick}>
                      Careers
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/contact' onClick={closeNavOnLinkClick}>
                  Contact Us
                </NavLink>
              </li>
          </ul>
        </div>
          
          {user === null ? (
            <div className='login-cont'>
              <NavLink className='btn btn-r text-white' to='/login' onClick={closeNavOnLinkClick}>
                <i className='fa-regular fa-user'></i> Login
              </NavLink>
            </div>
          ) : (
            <div className='dropdown '>
              <Link to='#' className='d-inline-block py-1 text-decoration-none' data-bs-toggle='dropdown'>
                <img className='rounded-circle mr-2' src={avatar} width='40' alt={user?.firstname} />{' '}
                <span className='h6 mb-0 ml-2 red-txt'> {user?.firstname}</span>
              </Link>
              <div className='dropdown-menu dropdown-menu-end'>
                <div className='d-flex align-items-start border-bottom px-3 py-1 mb-2' style={{ width: '16rem' }}>
                  <img className='rounded-circle' src={avatar} width='48' alt={user?.firstname} />
                  <div className='ps-2 py-1'>
                    <h6 className='fs-base mb-0'>
                      {user?.firstname} {user?.lastname}
                    </h6>
                    <small className='fs-xs py-2'>{user?.username}</small>
                  </div>
                </div>

                <Link to='/dashboard/dashboard' className='dropdown-item'>
                  <i className='fi-list opacity-60 me-2'></i>
                  Dashboard
                </Link>
                <Link to='/dashboard/quotations' className='dropdown-item'>
                  <i className='fi-list opacity-60 me-2'></i>
                  Quotations
                </Link>
                <Link to='/dashboard/rentals' className='dropdown-item'>
                  <i className='fi-list opacity-60 me-2'></i>
                  Rental Bookings
                </Link>
                <Link to='/dashboard/tours' className='dropdown-item'>
                  <i className='fi-list opacity-60 me-2'></i>
                  Tour Bookings
                </Link>
                <Link to='/dashboard/profile' className='dropdown-item'>
                  <i className='fi-user opacity-60 me-2'></i>
                  Profile
                </Link>
                <Link to='/dashboard/profile' className='dropdown-item'>
                  <i className='fi-lock opacity-60 me-2'></i>
                  Password & Security
                </Link>
                <Link to='/dashboard/profile' className='dropdown-item'>
                  <i className='fi-bell opacity-60 me-2'></i>
                  Notifications
                </Link>
                <div className='dropdown-divider'></div>
                <button className='dropdown-item' onClick={() => handleLogout()}>
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
