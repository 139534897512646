// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const fetchVehicleTypes = () => {
  return axios.get(`${baseURL}/vehicle-types`);
}
