// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBRipple } from 'mdb-react-ui-kit';
import { ClipLoader } from 'react-spinners';
import { BookingModalRental } from './BookingModalRental';

import { createBookingAsync } from '../../../app/booking/bookingSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loadVehicleTypesAsync } from '../../../app/vehicle-type/vehicle-typeSlice';

import fullSedan from '../../../images/courtesy-rental.jpg';
import compactSedan from '../../../images/compact-sedan.jpg';
// import premiumSedan from '../../../images/premium-sedan.png';
import fxfSuv from '../../../images/4x4-suv.jpg';
import midSuv from '../../../images/midsize-suv.png';
import seater8 from '../../../images/seater-8.jpg';
import seater14 from '../../../images/seater-14.jpg';
import seater26 from '../../../images/seater-26-30.jpg';
// import noImg from '../../../images/noImg.jpg';
import doubleCab from '../../../images/double-cab.jpg';

const carImages = [fullSedan, compactSedan, fxfSuv, doubleCab, midSuv, seater8, seater14, seater26];

export const CarRental: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [vehTyp, setVehTyp] = useState(0);
  const [load, setLoading] = useState(false);

  const { vehicleTypes, loading } = useAppSelector((state) => state.vehicleType);

  const handleModalOpen = (v: any) => {
    setVehTyp(v);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setVehTyp(0);
  };

  const handleBookingSubmit = (values: any) => {
    setLoading(true);
    dispatch(createBookingAsync(values));
    setTimeout(() => {
      navigate('/quote-preview');
    }, 3000);
    setShowModal(false);
    setVehTyp(0);
  };

  useEffect(() => {
    dispatch(loadVehicleTypesAsync());
  }, [dispatch]);

  if (load) {
    return (
      <div className='col-md-5 text-center mx-auto py-4'>
        <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
        <h3 className='text-muted'>Generating Quote...</h3>
      </div>
    );
  }

  return (
    <>
      <div className='container-fluid py-3 px-2 my-3'>
        <h1 className='text-center red-txt'>Long &amp; Short Term Car Rental</h1>
        <p className='col-md-6 mx-auto text-center lead'>
          Whether you need a car for a few days or on lease/longterm hire for several months or years we have you
          covered and we have the best rates for you. Wide variety of vehicles available just choose.
        </p>
        {loading ? (
          <div className='col-md-5 text-center mx-auto py-4'>
            <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
            <h3 className='text-muted'>Loading...</h3>
          </div>
        ) : (
          <div className='row gap-2 mx-auto justify-content-center'>
            {
            vehicleTypes.length === 0 ? (
            <>
            <div className="mx-auto my-4 text-center">
              <h3 className="text-center">No vehicles available at the moment.</h3>
              <small className="lead text-center"><i>Refresh the page or Check your network connection.</i></small>
            </div>
            </>
            ) : 
            vehicleTypes.filter((car: any) => car?.onFleet === true)
              .map((car: any, index) => (
                <MDBCard key={car.id} className='col-md-3 col-lg-3 shadow shadow-h p-0'>
                  <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                    <MDBCardImage className='car-img-bk' src={carImages[index]} fluid alt={car.type} />
                    <Link to={`/cars/${car.id}`}>
                      <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                    </Link>
                  </MDBRipple>
                  <MDBCardBody>
                    <MDBCardTitle>
                      <span className='red-txt'>{car.type}</span>
                      <br />
                      <small className='car-description'>
                        {car.description === 'No Vehicles Available' ? (
                          <i>Contact us to enquire</i>
                        ) : (
                          <i>{car.description}</i>
                        )}
                      </small>
                    </MDBCardTitle>
                    <MDBCardText>
                      {/* <span>{car.vehicles[0]?.seats} Seats</span>{' | '}
                            <span>{car.drive}</span>{' | '}
                            <span>{car.fuelType} Engine</span>
                            <br /> */}

                      {car?.rates.length === 0 ? (
                        <span>&mdash;</span>
                      ) : (
                        <strong>
                          ${car?.rates[0]?.withinTown?.USD} / K{car?.rates[0]?.withinTown?.ZMW} per day{' '}
                          <small>
                            <i>(Within town)</i>
                          </small>
                        </strong>
                      )}
                      <br />
                      {car?.rates.length === 0 ? (
                        <i>Contact us to enquire</i>
                      ) : (
                        <strong>
                          ${car.rates[0]?.outsideTown?.USD} / K{car?.rates[0]?.outsideTown?.ZMW} per day{' '}
                          <small>
                            <i>(Out of Town)</i>
                          </small>
                        </strong>
                      )}
                    </MDBCardText>
                    <hr />
                    <div className='d-flex justify-content-between'>
                      {car?.rates.length === 0 ? (
                        <button className='disabled btn btn-r'>Book Now</button>
                      ) : (
                        <button className='btn btn-r' onClick={() => handleModalOpen(car.id)}>
                          Book Now
                        </button>
                      )}
                      <Link to='/contact' className='btn btn-r'>
                        Enquire
                      </Link>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              ))
            }
          </div>
        )}
      </div>
      <BookingModalRental vt={vehTyp} show={showModal} onHide={handleModalClose} onSubmit={handleBookingSubmit} />
    </>
  );
};
