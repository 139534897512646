// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loadVehicleTypesAsync, selectVehicleTypeById } from '../../../app/vehicle-type/vehicle-typeSlice';
import { RootState } from '../../../app/store';
import { FaSave } from 'react-icons/fa';
import { convertDate } from '../../../utils/formatDateToString';

interface BookingModalProps {
  vt: number;
  show: boolean;
  onHide: () => void;
  onSubmit: (values: BookingFormData) => void;
}

interface BookingFormData {
  id: number | string;
  email: string;
  firstname: string;
  lastname: string;
  pickUpTown: string;
  pickUpArea: string;
  drivingToTown: string;
  drivingToCountry: string;
  dropOffTown: string;
  startDate: Date | any;
  endDate: Date | any;
  vehicleType: string | number;
  driveMode: string;
  numOfAdults: number;
  numOfChildren: number;
  selectedRate: string;
  bookingNumber: string;
  phone: string;
  status: string;
  totalDriverAllowance: number;
  totalBookingAmount: number;
  finalTotalAmount: number;
  discount: number;
  bookingRateAmount: number;
  driverRateAmount: number;
  pumpPrice: number;
  distance: number;
  fuelCost: number;
  tollFees: number;
  driverFood: number;
  busFare: number;
  driverLodging: number;
}

export const EditBookingModalRental: React.FC<BookingModalProps> = ({ vt, show, onHide, onSubmit }) => {
  const dispatch = useAppDispatch();
  const { quote } = useAppSelector((state: RootState) => state.booking);
  let vehicleType = useAppSelector((state: RootState) =>
    selectVehicleTypeById(state, quote?.booking?.vehicleType?.id | vt)
  );

  const formattedStartDate = convertDate(quote?.booking?.startDate);
  const formattedEndDate = convertDate(quote?.booking?.endDate);

  useEffect(() => {
    dispatch(loadVehicleTypesAsync());
  }, [dispatch]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='red-txt'>Edit Your Quote: #{quote?.booking?.bookingNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik<BookingFormData>
          initialValues={{
            id: quote?.booking?.id,
            email: quote?.booking?.email,
            firstname: quote?.booking?.firstname,
            lastname: quote?.booking?.lastname,
            pickUpTown: quote?.booking?.pickUpTown,
            pickUpArea: quote?.booking?.pickUpArea,
            drivingToTown: quote?.booking?.drivingToTown,
            drivingToCountry: quote?.booking?.drivingToCountry,
            dropOffTown: quote?.booking?.dropOffTown,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            vehicleType: vehicleType?.id,
            driveMode: quote?.booking?.driveMode,
            numOfAdults: quote?.booking?.numOfAdults,
            numOfChildren: quote?.booking?.numOfChildren,
            selectedRate: quote?.booking?.selectedRate,
            bookingNumber: quote?.booking?.bookingNumber,
            phone: quote?.booking?.phone,
            status: quote?.booking?.status,
            totalDriverAllowance: quote?.booking?.totalDriverAllowance,
            totalBookingAmount: quote?.booking?.totalBookingAmount,
            finalTotalAmount: quote?.booking?.finalTotalAmount,
            discount: quote?.booking?.discount,
            bookingRateAmount: quote?.booking?.bookingRateAmount,
            driverRateAmount: quote?.booking?.driverRateAmount,
            pumpPrice: quote?.booking?.pumpPrice,
            distance: quote?.booking?.distance,
            fuelCost: quote?.booking?.fuelCost,
            tollFees: quote?.booking?.tollFees,
            driverFood: quote?.booking?.driverFood,
            busFare: quote?.booking?.busFare,
            driverLodging: quote?.booking?.driverLodging,
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className='two-column-form'>
              <div className='form-row'>
                <div className='form-column'>
                  <Form.Group controlId='firstname'>
                    <Form.Label>Firstname</Form.Label>
                    <Field type='text' name='firstname' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Field type='email' name='email' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='pickUpTown'>
                    <Form.Label>Pick Up Town</Form.Label>
                    <Field type='text' name='pickUpTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToTown'>
                    <Form.Label>Driving To Town</Form.Label>
                    <Field type='text' name='drivingToTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToCountry'>
                    <Form.Label>Driving To Country</Form.Label>
                    <Field type='text' name='drivingToCountry' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='startDate'>
                    <Form.Label>Start Date/Time</Form.Label>
                    <Field type='datetime-local' name='startDate' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='driveMode'>
                    <Form.Label>Do you need a Driver?</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='Yes'
                        checked={values.driveMode === 'Chauffeur'}
                        onChange={handleChange}
                        value='Chauffeur'
                      />
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='No'
                        checked={values.driveMode === 'Self-drive'}
                        onChange={handleChange}
                        value='Self-drive'
                      />
                    </div>
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='selectedRate'>
                    <Form.Label>Currency</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='$ USD'
                        checked={values.selectedRate === 'USD'}
                        onChange={handleChange}
                        value='USD'
                      />
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='K ZMW'
                        checked={values.selectedRate === 'ZMW'}
                        onChange={handleChange}
                        value='ZMW'
                      />
                    </div>
                  </Form.Group>
                  <hr />
                </div>

                <div className='form-column'>
                  <Form.Group controlId='lastname'>
                    <Form.Label>Lastname</Form.Label>
                    <Field type='text' name='lastname' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='phone'>
                    <Form.Label>Phone</Form.Label>
                    <Field type='phone' name='phone' as={Form.Control} required />
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='pickUpArea'>
                    <Form.Label>Pick Up Area</Form.Label>
                    <Field type='text' name='pickUpArea' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='dropOffTown'>
                    <Form.Label>Drop Off Town</Form.Label>
                    <Field type='text' name='dropOffTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='vehicleType'>
                    <Form.Label>Vehicle Type</Form.Label>
                    <Field as='select' name='vehicleType' disabled className='form-control py-2' required>
                      <option value={vehicleType?.id}>{vehicleType?.type}</option>
                    </Field>
                  </Form.Group>

                  <hr />

                  <Form.Group controlId='endDate'>
                    <Form.Label>End Date/Time</Form.Label>
                    <Field type='datetime-local' name='endDate' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <div className='num-children gap-2 px-2'>
                    <Form.Group controlId='numOfAdults'>
                      <Form.Label>No. of Adults</Form.Label>
                      <Field type='number' name='numOfAdults' as={Form.Control} required />
                    </Form.Group>
                    <Form.Group controlId='numOfChildren'>
                      <Form.Label>No. of Children</Form.Label>
                      <Field type='number' name='numOfChildren' as={Form.Control} />
                    </Form.Group>
                  </div>
                  <hr />
                </div>
              </div>
              <hr />
              <Button className='btn-r' type='submit'>
                <FaSave /> Amend Quote
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
