// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchVehicles } from './vehicleAPI';


export interface VehicleState {
  loading: boolean;
  vehicles: any[];
}

const initialState: VehicleState = {
  loading: false,
  vehicles: [],
};

export const loadVehiclesAsync = createAsyncThunk(
  'vehicle/fetchVehicles',
  async () => {
    const response = await fetchVehicles();
    return response.data;
  }
);

export const hotelSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadVehiclesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadVehiclesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = action.payload;
      })
      .addCase(loadVehiclesAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectVehicle = (state: RootState) => state.vehicle.vehicles;
export const selectVehicleById = (state: RootState, vehicleId: number) => state.vehicle.vehicles.find(vehicle => vehicle.id === vehicleId);

export default hotelSlice.reducer;