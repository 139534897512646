// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

import contact from '../../images/cont-us.png';

export const Contact = () => {
  return (
    <>
      <section id='contact' className='contact-section container-fluid mx-auto'>
        <div className='contact-sect-container'>
          <div className='row reverse justify-content-between'>
            <div className='col-md-8 text-center text-lg-left'>
              <h2 className='display-4'>Get In Touch</h2>
              <hr className='divider' />
              <p className='section-subheading text-white mx-auto lead'>
                We'd love to hear from you! Get in touch with us for any inquiries or assistance.
              </p>
              <div className='contact-cta'>
                <div className='text-center mx-auto'>
                  <Link to='/contact' className='btn btn-lg btn-w red-txt wiggle-btn'>
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='contact-image mx-auto'>
                <img src={contact} className='img-fluid' alt='Contact City Drive' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
