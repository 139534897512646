// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loginUserAsync } from '../../../app/auth/authSlice';
import { getToken } from '../../../utils/localStorage';
import { RootState } from '../../../app/store';

export const InstantLogin = () => {
  const [errors, setErrors] = React.useState<any>('');

  const { loading } = useAppSelector((state: RootState) => state.auth);
  const { quote } = useAppSelector((state: RootState) => state.booking);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = getToken();

  if (user !== null) {
    window.location.href = '/dashboard/dashboard';
  }

  return (
    <div className='login-page-2 col-md-10 mx-auto py-5 shadow'>
      <div className='login-guide'>
        <div className='intro'>
          <h3 className='display-6'>Thank you for completing the payment!</h3>
          <h4 className='lead my-3'>
            You can view the quote and payment confirmation sent to the email you provided when generating the quote.
            <br />
            <br />
            <div className='text-center'>Or</div>
            <br />
            Proceed to login to your account with the credentials that were sent to the email{' '}
            {!quote?.booking ? (
              <></>
            ) : (
              <>
                [<i className='red-txt'>{quote?.booking?.email}</i>]
              </>
            )}{' '}
            you provided when generating the quote.
            <br />
          </h4>
          <br />
          <div className='text-center'>
            <Link to="/login"><i className='fa-solid fa-circle-arrow-right fa-2xl red-txt'></i></Link>
          </div>
          <br />
          <br />

          <h4 className='lead text-center'>
            Thank you for choosing{' '}
            <Link to='/' className='red-txt'>
              City Drive
            </Link>
          </h4>
        </div>
        <div className='intro-detail'></div>
      </div>
      <div className='login-container'>
        <h2 className='login-heading display-5'>Login</h2>
        <br />
        <div className='col-md-8 mx-auto text-center mb-3'>
          <small className='text-center mx-auto text-danger'>{errors}</small>
        </div>
        <Formik
          initialValues={{
            username: quote?.booking?.email || '',
            password: '',
          }}
          onSubmit={async (values: { username: string; password: string }) => {
            const req = await dispatch(loginUserAsync(values));
            // resetForm();
            if (req.meta.requestStatus === 'rejected') {
              setErrors(req.payload);
            }
            if (req.meta.requestStatus === 'fulfilled') {
              return navigate('/dashboard/dashboard');
            }
          }}
        >
          <Form className='login-form'>
            <div className='form-group'>
              <label htmlFor='username'>Username</label>
              <Field type='email' id='username' name='username' placeholder='Eg. abc@xyz.com' required />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <Field type='password' id='password' name='password' required />
            </div>
            {loading ? (
              <>
                <button type='submit' className='btn login-btn mx-auto btn-r' disabled>
                  Login
                </button>
                <ClipLoader color='#e81f29' size={30} />
              </>
            ) : (
              <button type='submit' className='btn login-btn mx-auto btn-r'>
                Login
              </button>
            )}
          </Form>
        </Formik>
        <br />
        <div className='sub-login'>
          <span>
            Don't have an Account?{' '}
            <Link to='/signup' className='red-txt'>
              Sign Up
            </Link>
          </span>
          <br />
          <span>
            Forgot Password?{' '}
            <Link to='/reset-password' className='red-txt'>
              Reset
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
