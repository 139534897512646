// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getUserAsync } from '../../../app/user/userSlice';
import jwtDecode from 'jwt-decode';
import { IDecodedUser } from '../../../interfaces/user';
import { getToken } from '../../../utils/localStorage';
import { formatDateTime } from '../../../utils/datetimeFomatter';
import { ClipLoader } from 'react-spinners';

export const RentalDetails = () => {
  const { id } = useParams();
  const { userInfo } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const tk = getToken();
  let d: IDecodedUser;
  let uid = 0;
  if (tk !== null) {
    d = jwtDecode(tk);
    uid = d.sub;
  }

  // Find the booking with the corresponding bookingId
  const booking = userInfo?.bookings.find((rental: any) => rental.id === Number(id));

  let currency = '';

  if (booking?.selectedRate === 'ZMW') {
    currency = 'K';
  } else if (booking?.selectedRate === 'USD') {
    currency = '$';
  }

  useEffect(() => {
    dispatch(getUserAsync(uid));
  }, [dispatch, uid]);

  if (!booking) {
    return <div>
      <ClipLoader color="#e81f29" size={50} />
    </div>
  }

  const startDateTime = new Date(booking.startDate);
  const endDateTime = new Date(booking.endDate);
  const { formattedDate: startDate } = formatDateTime(startDateTime);
  const { formattedDate: endDate } = formatDateTime(endDateTime);

  return (
    <div className="container py-3">
      <h1 className="heading text-center">Quotation Details</h1>
      <div className="card mx-auto col-md-8">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h5 className="card-title">Quotation Information</h5>
              <p className="card-text">
                <strong>Rental Number:</strong> {booking.bookingNumber}<br />
                <strong>Vehicle Type:</strong> {booking.vehicleType?.type}<br />
                <strong>Status:</strong>{' '}
                {booking.status === 'Pending Confirmation' ? (
                  <span className="badge text-white text-bg-warning">{booking.status}</span>
                ) : (
                  <span className="badge text-bg-secondary">{booking.status}</span>
                )}
                <br />
                <strong>Pick-up Town:</strong> {booking.pickUpTown} <br />
                <strong>Start Date:</strong> {startDate}<br />
                <strong>End Date:</strong> {endDate}<br />
                <strong>Duration:</strong> {booking.duration} days<br />
                <strong>Drive Mode:</strong> {booking.driveMode}<br />
                <strong>Driver:</strong> {booking.driveMode === 'Self-drive' ? 'Not Included' : booking.driver}<br />
                <strong>Number of Adults:</strong> {booking.numOfAdults}<br />
                <strong>Number of Children:</strong> {booking.numOfChildren}
              </p>
            </div>
            <div className="col-md-6">
              <h5 className="card-title">Contact Information</h5>
              <p className="card-text">
                <strong>Full Name:</strong> {booking.fullname}<br />
                <strong>Email:</strong> {booking.email}<br />
                <strong>Phone:</strong> {booking.phone}<br />
                <strong>Pick-up Area:</strong> {booking.pickUpArea}<br />
                <strong>Driving To Town:</strong> {booking.drivingToTown}<br />
                <strong>Driving To Country:</strong> {booking.drivingToCountry}<br />
                <strong>Drop-off Town:</strong> {booking.dropOffTown}<br />
                <strong>Total Driver Allowance:</strong> {currency} {booking.totalDriverAllowance}<br />
                <strong>Total Booking Amount:</strong> {currency} {booking.totalBookingAmount}<br />
                <strong>Final Total Amount:</strong> {currency} {booking.finalTotalAmount}<br />
                <strong>Discount:</strong> - {currency} {booking.discount}
              </p>
            </div>
          </div>
          <div className="text-center my-4 py-3">
            <button className="btn btn-primary mx-1">Edit</button>
            <button className="btn btn-r mx-1">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};
