// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBIcon } from 'mdb-react-ui-kit';

const faqData = [
  {
    question: 'How old do I need to be to rent a car?',
    answer:
      'All customers need to be 22 years and above. They need to possess a valid driver\'s license and ID. All local customers will have to present proof of residence in the form of a utility bill either Zesco bill or water bill.'
  },
  {
    question: 'How are payments made?',
    answer:
      'All payments should be made upfront before or at the time of collecting the vehicle. Payments can be made via cash, credit/debit card, bank transfer or cheque.'
  },
  {
    question: 'Do I need to make a down payment for booking to be confirmed?',
    answer:
      'Yes. All bookings need to be paid for atleast 50% of the invoice total for them to be secured.'
  },
  {
    question: 'What happens in the event of an accident?',
    answer:
      'All City Drive vehicles are comprehensively insured. In the event of an accident the client will pay the 10% excess of the damage costs and our insurance will cover the rest.'
  },
  {
    question: 'Is there a penalty for returning the car late?',
    answer:
      'We give a provision for all cars to be returned the following day on or before 10am. Any late returns beyond this time will require the customer to pay a full days hire for that day.'
  },
  {
    question: 'What is your cancellation policy? How do I access the fees schedule due to me?',
    answer:
      'All booking canceled will attract a cancellation fee of 50% of the invoice cancelled or the invoice amount paid up.'
  },
  {
    question: 'Can I pick up a vehicle in one town and leave it in another and vice versa?',
    answer:
      'City Drive provides one way rental fees within Zambia as well as within Southern Africa. You can pick up or drop off a vehicle in the major towns and Cities within Zambia as well as countries within Southern Africa e.g Botswana, Malawi, Zimbabwe, South Africa etc'
  },
  {
    question: 'If need arises can I swap for another car other than the one initially booked?',
    answer:
      'Yes that\'s possible. If the car costs more than the one initially booked then you will be required to pay the difference. If the car costs less than the one initially booked then a cancelation fee will apply on the first car with any resulting difference being refunded to you.'
  },
  {
    question: 'What is the fuel policy?',
    answer:
      'All fuel costs are born by the client. The vehicle will be given with a full tank and should be returned with a full tank.'
  },
  {
    question: 'What Happens in the event of a breakdown?',
    answer:
      'City Drive provides 24/7 road side assistance. Should there be a breakdown our rescue team will come your way to assist you. If it\'s a small problem that can be sorted out at the scene of breakdown then it will be fixed there and then. If it\'s a bigger problem then we will swap and give you a similar car.'
  },
  {
    question: 'Do you provide the extra tents or we have to bring them?',
    answer:
      'All extra ground tents can be provided at a Fee of $5.00/K50.00 Per day.'
  },
];

export const Faq = () => {
  return (
    <div className="faq-page py-4">
      <h1 className='text-center'>Frequently Asked Questions</h1>
      <MDBAccordion initialActive={0} className='shadow'>
      {faqData.map((faq, index) => (
        <MDBAccordionItem  key={index} collapseId={index} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; {faq.question}</>}>
          {faq.answer}
        </MDBAccordionItem>
      ))}
    </MDBAccordion>
    </div>
  );
};
