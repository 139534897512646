// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCar, FaMapMarkerAlt, FaCalendarAlt, FaUserFriends, FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loadVehicleTypesAsync, selectVehicleTypeById } from '../../../app/vehicle-type/vehicle-typeSlice';

import logo from '../../../images/logo.png';
import { ClipLoader } from 'react-spinners';

import { formatDateTime } from '../../../utils/datetimeFomatter';
import { createBookingAsync } from '../../../app/booking/bookingSlice';
import { EditBookingModalRental } from './EditBookingModalRental';
import { updateBookingAsync } from '../../../app/booking/bookingSlice';
import { createTokenAsync } from '../../../app/payment/paymentSlice';
import { RootState } from '../../../app/store';

const DPO_URL = process.env.REACT_APP_DPO_URL;

export const QuotePreview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { quote } = useAppSelector((state: RootState) => state.booking);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [vehTyp, setVehTyp] = useState(0);

  const handleModalOpen = (v: any) => {
    setVehTyp(v);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setVehTyp(0);
  };

  const handleBookingSubmit = (values: any) => {
    setLoading(true);
    dispatch(createBookingAsync(values));
    setTimeout(() => {
      navigate('/quote-preview');
    }, 3000);
    setShowModal(false);
    setVehTyp(0);
  };

  const handleEditBookingSubmit = (values: any) => {
    setLoading(true);
    dispatch(updateBookingAsync(values));
    navigate('/processing');
    setTimeout(() => {
      navigate('/quote-preview');
    }, 3000);
    setShowModal(false);
    setVehTyp(0);
  };

  const { paymentToken } = useAppSelector((state: RootState) => state.payment);

  const handlePay = async (id: number) => {
    try {
      const req = await dispatch(createTokenAsync(id));
      navigate('/processing');
      if (req.meta.requestStatus === 'fulfilled') {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        window.location.href = DPO_URL + req.payload?.transToken;
      }
    } catch (error) {
      console.error('Error while processing payment:', error);
    }
  };

  const { vehicleTypes } = useAppSelector((state: RootState) => state.vehicleType);

  useEffect(() => {
    dispatch(loadVehicleTypesAsync());
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [dispatch]);

  const r = parseInt(quote?.booking?.vehicleType);
  const createdDate = new Date(quote?.booking?.createdAt);
  const updatedDate = new Date(quote?.booking?.updatedAt);
  const { formattedDate, formattedTime } = formatDateTime(createdDate);
  const { formattedDate: formattedUpdatedDate, formattedTime: formattedUpdatedTime } = formatDateTime(updatedDate);

  const startDateTime = new Date(quote?.booking?.startDate);
  const endDateTime = new Date(quote?.booking?.endDate);
  const { formattedDate: startDate, formattedTime: startTime } = formatDateTime(startDateTime);
  const { formattedDate: endDate, formattedTime: endTime } = formatDateTime(endDateTime);

  const vt = useAppSelector((state: RootState) => selectVehicleTypeById(state, r));

  try {
    // The 'booking' is fetched asynchronously, so ensure it exists before accessing its properties
    if (loading) {
      return (
        <div className='col-md-5 text-center mx-auto py-4'>
          <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
          <h3 className='text-muted h3'>Loading Quote...</h3>
        </div>
      );
    }

    if (!quote?.booking) {
      return (
        <>
          <div className='col-md-5 text-center mx-auto py-4'>
            <h4 className='text-muted h4'>Sorry! Something went wrong while loading your quote.</h4>
            <p className='text-muted lead'>It's not your fault.</p>
            <div className='text-center mx-auto py-3'>
              <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-r wiggle-btn'>
                Please try again
              </Link>
            </div>
          </div>
        </>
      );
    }

    let currency = '';
    if (quote?.booking?.selectedRate === 'ZMW') {
      currency = 'K';
    } else if (quote?.booking?.selectedRate === 'USD') {
      currency = '$';
    }

    // Normal rendering logic that depends on 'booking'
    return (
      <>
        <div className='col-md-9 shadow mx-auto py-5 px-3'>
          <div id='invoiceholder col-md-8 mx-auto'>
            <div id='invoice' className='effect2'>
              <div className='row justify-content-between container mx-auto'>
                <div className='info col-md-4 py-2'>
                  <img src={logo} className='logo' alt='City Drive' />
                  <p>
                    reservations@citydriverentacar.com
                    <br />
                    +260 976 760 159
                  </p>
                </div>
                <div className='title col-md-6 py-2'>
                  <h1>Quotation #{quote?.booking?.bookingNumber}</h1>
                  <p>
                    {formattedDate === formattedUpdatedDate && formattedTime === formattedUpdatedTime ? (
                      <>
                        Issued: {formattedDate} at {formattedTime}
                        <br />
                        Status: <span className='p-2'>{quote?.booking?.status}</span>
                      </>
                    ) : (
                      <>
                        Issued: {formattedDate} at {formattedTime}
                        <br />
                        Last Updated: {formattedUpdatedDate} at {formattedUpdatedTime}
                        <br />
                        Status: <span className='p-2'>{quote?.booking?.status}</span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className='row' id='invoice-mid'>
                <div className='info col-md-4'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title'>Client Details </h4>
                      <div className='card-text py-3'>
                        <div className='row'>
                          <div className='col'>
                            <FaUser /> {quote?.booking?.firstname} {quote?.booking?.lastname}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaEnvelope /> {quote?.booking?.email}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaPhone /> {quote?.booking?.phone}
                          </div>
                        </div>
                        <hr />
                        <div className='col'>
                          <FaMapMarkerAlt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id='project' className='col-md-8'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title'>Booking Information</h4>
                      <div className='card-text py-3'>
                        <div className='row'>
                          <div className='col'>
                            <FaCar /> <strong>Vehicle Type:</strong> {quote?.booking?.vehicleType.type || vt?.type}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaMapMarkerAlt /> <strong>Pick-up & Destination: </strong>
                            {quote?.booking?.pickUpTown === quote?.booking?.drivingToTown ? (
                              <>{quote?.booking?.pickUpTown} (Within town)</>
                            ) : (
                              <>
                                {quote?.booking?.pickUpTown} &mdash; {quote?.booking?.drivingToTown} {' | '}{' '}
                                {quote?.booking?.drivingToCountry === 'Zambia' ? (
                                  <small>
                                    <strong>Within {quote?.booking?.drivingToCountry}</strong>
                                  </small>
                                ) : (
                                  <small>
                                    <strong>To {quote?.booking?.drivingToCountry}</strong>
                                  </small>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaMapMarkerAlt /> <strong>Drop-off:</strong> {quote?.booking?.dropOffTown}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col'>
                            <FaCalendarAlt /> <strong>Start & End Date:</strong> {startDate} {startTime} &mdash;{' '}
                            {endDate} {endTime} ({quote?.booking?.duration} days)
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaCar /> <strong>Drive Mode:</strong> {quote?.booking?.driveMode}
                          </div>
                          <div className='col-6'>
                            <FaUser /> <strong>Driver:</strong>{' '}
                            {quote?.booking?.driveMode === 'Self-drive' ? 'Not Included' : 'Not Assigned'}
                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-6'>
                            <FaUserFriends /> <strong>Adults:</strong> {quote?.booking?.numOfAdults}
                          </div>
                          <div className='col-6'>
                            <FaUserFriends /> <strong>Children:</strong> {quote?.booking?.numOfChildren}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id='invoice-bot'>
                <div id='table' className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr className='tabletitle'>
                        <td className='item'>
                          <h4>Service Item</h4>
                        </td>
                        <td className='rate'>
                          <h4>Rate</h4>
                        </td>
                        <td className='duration'>
                          <h4>Duration</h4>
                        </td>
                        <td className='rate'></td>
                        <td className='subtotal'>
                          <h4>Sub-total</h4>
                        </td>
                      </tr>
                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'>Car Rental</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {currency} {quote?.booking?.bookingRateAmount}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>{quote?.booking?.duration} days</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {currency}{' '}
                            {parseFloat(quote?.booking?.bookingRateAmount) * parseFloat(quote?.booking?.duration)}
                          </p>
                        </td>
                      </tr>
                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'>Driver Allowance</p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {quote?.booking?.driveMode === 'Self-drive' ? (
                              <>&mdash;</>
                            ) : (
                              <>
                                {currency} {quote?.booking?.driverRateAmount}
                              </>
                            )}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {quote?.booking?.driveMode === 'Self-drive' ? (
                              <>&mdash;</>
                            ) : (
                              <>{quote?.booking?.duration} days</>
                            )}
                          </p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            {quote?.booking?.driveMode === 'Self-drive' ? (
                              <>{currency} 0.00</>
                            ) : (
                              <>
                                {currency} {quote?.booking?.totalDriverAllowance}
                              </>
                            )}
                          </p>
                        </td>
                      </tr>

                      {quote?.booking?.fuelCost === null || quote?.booking?.fuelCost <= 0 ? (
                        ''
                      ) : (
                        <>
                          <tr className='service'>
                            <td className='tableitem'>
                              <p className='itemtext'>One Way Rental Cost</p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>
                                  {currency}{' '}
                                  {(
                                    parseFloat(quote?.booking?.fuelCost) +
                                    parseFloat(quote?.booking?.tollFees) +
                                    parseFloat(quote?.booking?.busFare) +
                                    parseFloat(quote?.booking?.driverFood) +
                                    parseFloat(quote?.booking?.driverLodging)
                                  ).toFixed(2)}
                                </>
                              </p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>&mdash;</>
                              </p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'></p>
                            </td>
                            <td className='tableitem'>
                              <p className='itemtext'>
                                <>
                                  {currency}{' '}
                                  {(
                                    parseFloat(quote?.booking?.fuelCost) +
                                    parseFloat(quote?.booking?.tollFees) +
                                    parseFloat(quote?.booking?.busFare) +
                                    parseFloat(quote?.booking?.driverFood) +
                                    parseFloat(quote?.booking?.driverLodging)
                                  ).toFixed(2)}
                                </>
                              </p>
                            </td>
                          </tr>
                        </>
                      )}

                      <tr className='service'>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'></p>
                        </td>
                        <td className='tableitem'></td>
                        <td className='tableitem'>
                          <h6>Discount Applied</h6>
                        </td>
                        <td className='tableitem'>
                          <p className='itemtext'>
                            &mdash; {currency} {quote?.booking?.discount} (7%)
                          </p>
                        </td>
                      </tr>
                      <tr className='tabletitle'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='rate'>
                          <h4>Total</h4>
                        </td>
                        <td className='payment'>
                          <h4>
                            {currency}
                            {quote?.booking?.fuelCost !== null
                              ? (
                                  parseFloat(quote?.booking?.bookingRateAmount) * parseFloat(quote?.booking?.duration) +
                                  parseFloat(quote?.booking?.totalDriverAllowance) +
                                  parseFloat(quote?.booking?.tollFees) +
                                  parseFloat(quote?.booking?.busFare) +
                                  parseFloat(quote?.booking?.driverFood) +
                                  parseFloat(quote?.booking?.driverLodging) -
                                  parseFloat(quote?.booking?.discount)
                                ).toFixed(2)
                              : (
                                  parseFloat(quote?.booking?.bookingRateAmount) * parseFloat(quote?.booking?.duration) +
                                  parseFloat(quote?.booking?.totalDriverAllowance) -
                                  parseFloat(quote?.booking?.discount)
                                ).toFixed(2)}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='col-md-8 mx-auto text-center' id='legalcopy'>
                  <p className='legal red-txt lead' style={{ fontSize: '13px' }}>
                    {' '}
                    <sup>*</sup> You are required to pay at least 50% of the total amount as down payment for your
                    booking to be reserved.
                  </p>
                </div>

                <div className='row justify-content-between my-4'>
                  <div className='col-md-2 row justify-content-between'>
                    <div className='col-md-2'>
                      <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-secondary'>
                        Cancel
                      </Link>
                    </div>

                    <div className='col-md-2'>
                      <button className='btn btn-lg btn-secondary' onClick={() => handleModalOpen(vt?.id)}>
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <button className='btn btn-lg btn-r' onClick={() => handlePay(quote?.booking?.id)}>
                      Proceed to Pay
                    </button>
                  </div>
                </div>

                <hr />

                <div className='col-md-8 mx-auto text-center' id='legalcopy'>
                  <p className='legal lead'>
                    <strong>
                      Thank you for considering <span className='red-txt'>City Drive</span>!
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditBookingModalRental
          vt={vehTyp}
          show={showModal}
          onHide={handleModalClose}
          onSubmit={handleEditBookingSubmit}
        />
      </>
    );
  } catch (error) {
    return (
      <>
        <div className='col-md-5 text-center mx-auto py-4'>
          <h4 className='text-muted h4'>Sorry! Something went wrong while generating your quote.</h4>
          <p className='text-muted lead'>It's not your fault.</p>
          <div className='text-center mx-auto py-3'>
            <Link to='/car-rental/long-and-short-term' className='btn btn-lg btn-r wiggle-btn'>
              Please try again
            </Link>
          </div>
        </div>
      </>
    );
  }
};
