import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loadVehicleTypesAsync } from '../../../app/vehicle-type/vehicle-typeSlice';

interface BookingModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (values: BookingFormData) => void;
}

interface BookingFormData {
  email: string;
  fullname: string;
  phone: string;
  pickUpTown: string;
  pickUpArea: string;
  drivingToTown: string;
  drivingToCountry: string;
  dropOffTown: string;
  startDate: string;
  endDate: string;
  vehicleType: string;
  driveMode: string;
  numOfAdults: number;
  numOfChildren: number;
  selectedRate: string;
}

export const BookingModal: React.FC<BookingModalProps> = ({ show, onHide, onSubmit }) => {
  const dispatch = useAppDispatch();

  const { vehicleTypes } = useAppSelector((state: any) => state.vehicleType);

  // getDaysBetweenDates(startDateTime: string, endDateTime: string): number {
  //   const start = new Date(startDateTime);
  //   const end = new Date(endDateTime);

  //   console.log(start);
  //   console.log(end);

  //   const timeDifference = Math.abs(end.getTime() - start.getTime());
  //   const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  //   console.log(daysDifference);

  //   return daysDifference;
  // }

  useEffect(() => {
    dispatch(loadVehicleTypesAsync());
  }, [dispatch]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='red-txt'>Rent a Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik<BookingFormData>
          initialValues={{
            email: '',
            fullname: '',
            phone: '',
            pickUpTown: '',
            pickUpArea: '',
            drivingToTown: '',
            drivingToCountry: 'Zambia',
            dropOffTown: '',
            startDate: '',
            endDate: '',
            vehicleType: '',
            driveMode: '',
            numOfAdults: 0,
            numOfChildren: 0,
            selectedRate: '',
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className='two-column-form'>
              <div className='form-row'>
                <div className='form-column'>
                  <Form.Group controlId='fullname'>
                    <Form.Label>Full Name</Form.Label>
                    <Field type='text' name='fullname' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='pickUpTown'>
                    <Form.Label>Pick Up Town</Form.Label>
                    <Field type='text' name='pickUpTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToTown'>
                    <Form.Label>Driving To Town</Form.Label>
                    <Field type='text' name='drivingToTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToCountry'>
                    <Form.Label>Driving To Country</Form.Label>
                    <Field type='text' name='drivingToCountry' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='startDate'>
                    <Form.Label>Start Date/Time</Form.Label>
                    <Field type='datetime-local' name='startDate' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='driveMode'>
                    <Form.Label>Do you need a Driver?</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='Yes'
                        checked={values.driveMode === 'Chauffeur'}
                        onChange={handleChange}
                        value='Chauffeur'
                      />
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='No'
                        checked={values.driveMode === 'Self-drive'}
                        onChange={handleChange}
                        value='Self-drive'
                      />
                    </div>
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='selectedRate'>
                    <Form.Label>Currency</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='$ USD'
                        checked={values.selectedRate === 'USD'}
                        onChange={handleChange}
                        value='USD'
                      />
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='K ZMW'
                        checked={values.selectedRate === 'ZMW'}
                        onChange={handleChange}
                        value='ZMW'
                      />
                    </div>
                  </Form.Group>
                  <hr />
                </div>

                <div className='form-column'>
                  <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Field type='email' name='email' as={Form.Control} required />
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='phone'>
                    <Form.Label>Phone</Form.Label>
                    <Field type='phone' name='phone' as={Form.Control} required />
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='pickUpArea'>
                    <Form.Label>Pick Up Area</Form.Label>
                    <Field type='text' name='pickUpArea' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='dropOffTown'>
                    <Form.Label>Drop Off Town</Form.Label>
                    <Field type='text' name='dropOffTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='vehicleType'>
                    <Form.Label>Choose Vehicle Type</Form.Label>
                    <Field as='select' name='vehicleType' className='form-control py-2' required>
                      <option value=''>Select Type</option>
                      {vehicleTypes &&
                        vehicleTypes
                          .filter((v: any) => v.status === 'available')
                          .map((v: any) => (
                            <option key={v.id} value={v.id}>
                              {v.type}
                            </option>
                          ))}
                    </Field>
                  </Form.Group>

                  <hr />

                  <Form.Group controlId='endDate'>
                    <Form.Label>End Date/Time</Form.Label>
                    <Field type='datetime-local' name='endDate' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <div className='num-children gap-2 px-2'>
                    <Form.Group controlId='numOfAdults'>
                      <Form.Label>No. of Adults</Form.Label>
                      <Field type='number' name='numOfAdults' as={Form.Control} required />
                    </Form.Group>
                    <Form.Group controlId='numOfChildren'>
                      <Form.Label>No. of Children</Form.Label>
                      <Field type='number' name='numOfChildren' as={Form.Control} />
                    </Form.Group>
                  </div>
                  <hr />
                </div>
              </div>
              <hr />
              <Button className='btn-r' type='submit'>
                Get Quote
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
