// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, useFormikContext } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { verifyUserAsync } from '../../../app/auth/authSlice';
import { ClipLoader } from 'react-spinners';
import { obfuscateEmail } from '../../../utils/obfuscateEmail';

export const VerifyOtp = () => {
  const location = useLocation();
  const email = location.state?.email || '';
  const verificationError = location.state?.errors || '';
  const [errors, setErrors] = React.useState<any>('');
  const { loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const obfuscatedEmail = obfuscateEmail(email);

  return (
    <div className='signup-page py-4'>
      <div className='signup-container'>
        <h2 className='signup-heading'>OTP</h2>
        <h6 className='signup-subheading text-center'>{verificationError}</h6>
        <h6 className='signup-subheading text-center'>Enter the OTP that was sent to you</h6>
        <p className='text-center m-0'><small>{obfuscatedEmail}</small></p>
        <br />
        <div className='mx-auto text-center mb-3'>
          <small className='text-center mx-auto text-danger'>{errors}</small>
        </div>
        <Formik
          initialValues={{
            username: email || '',
            otp: '',
          }}
          onSubmit={async (values: { username: string; otp: string; }) => {
            const req: any = await dispatch(verifyUserAsync(values));
            if (req.meta.requestStatus === 'rejected') {
              setErrors(req.payload);
            }
            if (req.payload.status === 400) {
              setErrors(`${req.payload.message}. Try again`);
            }
            if (req.payload.status === 200) {
              return navigate('/login', { replace: true });
            }
          }}
        >
          {({ values }) => (
            <Form className='signup-form'>
             
              <div className='form-group'>
                <label htmlFor='otp'>OTP</label>
                <Field type='text' id='otp' name='otp' placeholder='xxxxxx' maxLength={6} required />
              </div>

              {loading ? (
                <>
                  <button type='submit' className='btn signup-btn mx-auto btn-r' disabled>
                    Verify OTP
                  </button>
                  <ClipLoader color='#e81f29' size={30} />
                </>
              ) : (
                <button
                  type='submit'
                  className='btn signup-btn mx-auto btn-r'
                  disabled={values.otp.split('').length < 6}
                >
                  Verify OTP
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
