// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';

export const SubmissionSuccess = () => {
  return (
    <div className="success-container">
      <div className="icon-check-success success-icon">
        <i className="fa-regular fa-circle-check fa-2x"></i>
      </div>
      <h1>Form submitted successfully!</h1>
      <p>Thank you for submitting. We will get in touch with you shortly.</p>

      <Link to="/" className="btn btn-lg btn-r text-center">Go Home</Link>

    </div>
  );
};


