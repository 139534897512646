// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useAppSelector } from '../../../app/hooks';
import { selectVehicleTypeById } from '../../../app/vehicle-type/vehicle-typeSlice';
import { RootState } from '../../../app/store';
import { getDaysBetweenDates } from '../../../utils/getDaysBetweenDates';

interface BookingModalProps {
  vt: number;
  show: boolean;
  onHide: () => void;
  onSubmit: (values: BookingFormData) => void;
}

interface BookingFormData {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  pickUpTown: string;
  pickUpArea: string;
  drivingToTown: string;
  drivingToCountry: string;
  dropOffTown: string;
  startDate: Date | null;
  endDate: Date | null;
  vehicleType: string | number;
  driveMode: string;
  numOfAdults: number;
  numOfChildren: number;
  selectedRate: string;
}

export const BookingModalRental: React.FC<BookingModalProps> = ({ vt, show, onHide, onSubmit }) => {
  let vehicleType = useAppSelector((state: RootState) => selectVehicleTypeById(state, vt));

  const [numOfDays, setNumOfDays] = useState(0);
  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(new Date());
  const [startError, setStartError] = useState('');
  const [endError, setEndError] = useState('');

  const validateStart = () => {
    const now = new Date();
    if (!start) {
      setStartError('Start date/time is required');
      return false;
    }
    if (start < now) {
      setStartError("Start date/time can't be in the past");
      return false;
    }
    if (start < new Date(now.getTime() + 2 * 60 * 60 * 1000)) {
      setStartError('Start date/time must be at least 2 hours ahead of the current time');
      return false;
    }
    setStartError('');
    return true;
  };
  const validateEnd = () => {
    if (end < start) {
      setEndError('End date/time must be after the start date/time');
      return false;
    }
    if (!end) {
      setEndError('End date/time is required');
      return false;
    }
    setEndError('');
    return true;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    pickUpTown: Yup.string().required('Pickup Town is required'),
    drivingToTown: Yup.string().required('Driving To Town is required'),
    dropOffTown: Yup.string().required('Dropoff Town is required'),
    vehicleType: Yup.mixed().required('Vehicle Type is required'),
    numOfAdults: Yup.number().required('Number of Adults is required').min(1, 'Number of Adults must be at least 1'),
    numOfChildren: Yup.number().min(0, 'Number of Children cannot be less than 0'),
    driveMode: Yup.string().required('Please indicate if you need a driver or not'),
    selectedRate: Yup.string().required('Currency is required'),
  });

  useEffect(() => {
    setNumOfDays(getDaysBetweenDates(start?.toString(), end?.toString()) || 0);
    validateStart();
    validateEnd();
  }, [start, end]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='red-txt'>Rent a Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik<BookingFormData>
          initialValues={{
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            pickUpTown: '',
            pickUpArea: '',
            drivingToTown: '',
            drivingToCountry: 'Zambia',
            dropOffTown: '',
            startDate: null,
            endDate: null,
            vehicleType: vehicleType?.id,
            driveMode: '',
            numOfAdults: 0,
            numOfChildren: 0,
            selectedRate: 'ZMW',
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit} className='two-column-form'>
              <div className='form-row'>
                <div className='form-column'>
                  <Form.Group controlId='firstname'>
                    <Form.Label>Firstname</Form.Label>
                    <Field type='text' name='firstname' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Field type='email' name='email' as={Form.Control} required />
                    <ErrorMessage name='email' component='div' className='error-message' />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='pickUpTown'>
                    <Form.Label>Pickup Town</Form.Label>
                    <Field type='text' name='pickUpTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToTown'>
                    <Form.Label>Driving To Town</Form.Label>
                    <Field type='text' name='drivingToTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='drivingToCountry'>
                    <Form.Label>Driving To Country</Form.Label>
                    <Field type='text' name='drivingToCountry' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='startDate'>
                    <Form.Label>Start Date & Pickup Time</Form.Label>
                    <Field
                      type='datetime-local'
                      name='startDate'
                      as={Form.Control}
                      required
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formikProps.handleChange(e);
                        if (e.target.value) {
                          setStart(new Date(e.target.value));
                        }
                        validateStart();
                      }}
                    />
                    {startError !== '' && <div className='error-message'>{startError}</div>}
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='driveMode'>
                    <Form.Label>Do you need a Driver?</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='Yes'
                        checked={formikProps.values.driveMode === 'Chauffeur'}
                        onChange={formikProps.handleChange}
                        value='Chauffeur'
                      />
                      <Form.Check
                        type='radio'
                        name='driveMode'
                        label='No'
                        checked={formikProps.values.driveMode === 'Self-drive'}
                        onChange={formikProps.handleChange}
                        value='Self-drive'
                      />
                      <ErrorMessage name='driveMode' component='div' className='error-message' />
                    </div>
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='selectedRate'>
                    <Form.Label>Currency</Form.Label>
                    <div className='driver-radio px-2'>
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='$ USD'
                        checked={formikProps.values.selectedRate === 'USD'}
                        onChange={formikProps.handleChange}
                        value='USD'
                      />
                      <Form.Check
                        type='radio'
                        name='selectedRate'
                        label='K ZMW'
                        checked={formikProps.values.selectedRate === 'ZMW'}
                        onChange={formikProps.handleChange}
                        value='ZMW'
                      />
                    </div>
                    <ErrorMessage name='selectedRate' component='div' className='error-message' />
                  </Form.Group>
                  <hr />
                </div>

                <div className='form-column'>
                  <Form.Group controlId='lastname'>
                    <Form.Label>Lastname</Form.Label>
                    <Field type='text' name='lastname' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='phone'>
                    <Form.Label>Phone</Form.Label>
                    <Field type='phone' name='phone' as={Form.Control} required />
                  </Form.Group>
                  <hr />
                  <Form.Group controlId='pickUpArea'>
                    <Form.Label>Pickup Area</Form.Label>
                    <Field type='text' name='pickUpArea' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='dropOffTown'>
                    <Form.Label>Dropoff Town</Form.Label>
                    <Field type='text' name='dropOffTown' as={Form.Control} required />
                  </Form.Group>
                  <hr />

                  <Form.Group controlId='vehicleType'>
                    <Form.Label>Vehicle Type</Form.Label>
                    <Field as='select' name='vehicleType' disabled className='form-control py-2' required>
                      <option value={vehicleType?.id}>{vehicleType?.type}</option>
                    </Field>
                  </Form.Group>

                  <hr />

                  <Form.Group controlId='endDate'>
                    <Form.Label>End Date & Dropoff Time</Form.Label>
                    <Field
                      type='datetime-local'
                      name='endDate'
                      as={Form.Control}
                      required
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formikProps.handleChange(e);
                        if (e.target.value) {
                          setEnd(new Date(e.target.value));
                        }
                        validateEnd();
                      }}
                    />
                    {endError !== '' && <div className='error-message'>{endError}</div>}
                  </Form.Group>

                  <hr />

                  <Form.Group controlId='numOfHireDays'>
                    <Form.Label>Number of Hire Days</Form.Label>
                    <br />
                    <Form.Label>{numOfDays}</Form.Label>
                    {numOfDays < 2 && <span className='error-message mx-2'>Minimum number of hire days is 2</span>}
                  </Form.Group>

                  <hr />

                  <div className='num-children gap-2 px-2'>
                    <Form.Group controlId='numOfAdults'>
                      <Form.Label>No. of Adults</Form.Label>
                      <Field type='number' name='numOfAdults' as={Form.Control} required />
                      <ErrorMessage name='numOfAdults' component='div' className='error-message' />
                    </Form.Group>
                    <Form.Group controlId='numOfChildren'>
                      <Form.Label>No. of Children</Form.Label>
                      <Field type='number' name='numOfChildren' as={Form.Control} />
                      <ErrorMessage name='numOfChildren' component='div' className='error-message' />
                    </Form.Group>
                  </div>
                  <hr />
                </div>
              </div>
              <hr />
              <Button
                className='btn-r'
                type='submit'
                disabled={!!(formikProps.isSubmitting || !formikProps.isValid) || numOfDays < 2}
              >
                Get Quote
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
