// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

export const TermsOfService = () => {
  return (
    <>
      <div className='col-md-8 trading-conditions-container mx-auto px-5 py-3 shadow'>
        <h1 className='text-center trading-conditions-title py-2'>Standard Trading Conditions</h1>

        <div className='condition card'>
          <h2>1.0 Rental Days</h2>
          <p>
            City Drive will rent out its vehicles at a daily charge. This fee will vary according to the type of vehicle
            required by a client. The minimum charge will be based on one calendar day and not 24hrs. Days for which the
            car is rented out are counted starting from the first calendar day when the car is collected. This is
            regardless of the time the car is collected on the first calendar day.
          </p>
        </div>

        <div className='condition card'>
          <h2>2.0 Eligibility</h2>
          <p>
            The company will accept reservations from clients with a valid driver's license which should have been held
            for at least two years and upon submission of essential personal information i.e. NRC NO, physical address,
            Contact details, Referees, Place of work. Vehicles will not be rented out to underage individuals below the
            age of 22 years.
          </p>
        </div>
        <div className='condition card'>
          <h2>3.0 Rescue and Loss of Use</h2>
          <p>
            <strong>3.1</strong> In the event of City Drive having to do a rescue and upon confirmation of the breakdown
            having occurred, all towing charges over and above the insurance cover of K600.00 within Lusaka and
            K3,500.00 outside Lusaka and the associated costs of the rescue e.g. fuel costs will be borne by the client.
            <br />
            <br />
            <strong>3.2</strong> In the event of an accident City Drive reserves the right to charge loss of business
            use to the client for the period when the car will not be in use by Vehicle Portal as a revenue generating
            asset.
          </p>
        </div>

        <div className='condition card'>
          <h2>4.0 Vehicle Condition and Inspection</h2>
          <p>
            <strong>4.1</strong> Clients are expected to inspect the car for any damages/abnormalities before collecting
            the car. Such inspections should be done in the presence of both the client and Vehicle Portal
            representative. Any abnormalities /damages should be noted and both the client and the Vehicle Portal
            representative should sign on the inspection form.
            <br />
            <br />
            <strong>4.2</strong> Fuel is the client's responsibility. City Drive reserves the right to provide the car
            with or without fuel depending on circumstances. In the event that fuel is provided the client is expected
            to return the car with the same amount of fuel which was provided with the car.
          </p>
        </div>

        <div className='condition card'>
          <h2>5.0 Vehicle Insurance</h2>
          <p>
            <strong>5.1</strong> All vehicles will be provided in good condition. Apart from reasonable wear and tear
            clients are expected to return the vehicle in the same good condition. Whilst our vehicles are
            comprehensively insured, any damages or loss of vehicle property whilst the car is in the clients' custody
            and the associated cost of repair will be borne by the client over and above the insurance cover. This means
            the client will cover 10% of the damage costs with a minimum of K1, 000.00. Any damages amounting to K1,
            000.00 and below equaling the minimum excess insurance payable will be borne by the client in their
            entirety. The 10% excess payable by the client will also apply in the event of a write off. The vehicle will
            be deemed to be in the clients' custody upon collection and handing over keys to the client.
            <br />
            <br />
            <strong>5.2</strong> This 10% excess on damages and on write-offs is payable immediately the accident
            happens.
          </p>
        </div>

        <div className='condition card'>
          <h2>6.0 Security Deposit</h2>
          <p>
            City Drive will accept security deposit equal to the minimum insurance excess of K1, 000.00. This has to be
            paid in cash or credit card. This deposit is refundable upon vehicle return provided they are no damages to
            the car. If they are damages then Vehicle Portal will deduct an amount to cover the damages and refund the
            difference. If the cost of damages is above the K1, 000.00 then the client will top up the difference.
          </p>
        </div>
        <div className='condition card'>
          <h2>7.0 Vehicle Return</h2>
          <p>
            All vehicles are expected to be returned on time. Any late returns will attract a penalty equal to a full
            day's charge. An allowance of 1 hour from the time of return is given. If the client decides to extend the
            time of the hire period whilst the vehicle is in the client's custody, then the client should communicate
            this. In this case, there shall be an additional charge for the extended hire period. Extension of the
            rental period is not automatic hence clients are advised to communicate on time. In the event of an
            allowance given to bring back the car on or before 10 am the following day, any car brought back after 11 am
            will attract a full day's charge.
          </p>
        </div>
        <div className='condition card'>
          <h2>8.0 Reservation and Cancellation Policy</h2>
          <p>
            <strong>8.1</strong> Any cancellation of the vehicle reservation will attract a Fee 50% of the total quote,
            invoice accrued and due.
            <br />
            <br />
            <strong>8.2</strong> A reservation will only be confirmed on payment of 50% deposit of the proforma invoice.
            The 50% balance should be paid upon collecting the car. However, full payment can be made for reservations
            to be confirmed either by cash or credit card should the client choose to.
          </p>
        </div>
        <div className='condition card'>
          <h2>9.0 Payment of Rental Invoices</h2>
          <p>
            <strong>9.1</strong> If any amount due and payable by the client is not paid within the agreed term, the
            client will be liable for interest at the maximum prevailing market rate. Such interest will be calculated
            and paid monthly in advance. If the interest is not paid as aforesaid, the interest will be added to the
            principal sum, and the whole amount will form the principal debt, which will bear interest as described.
            <br />
            <br />
            <strong>9.2</strong> In the event of City Drive instructing attorneys to collect from the client an amount
            owing to City Drive, the client agrees to pay all costs on the scale as between attorney and own client,
            including collection charges.
          </p>
        </div>
        <div className='condition card'>
          <h2>10.0 Estimated Time of Delivery</h2>
          <p>
            <strong>10.1</strong> Our estimated time of delivery is 48 hours. This means that if you book a vehicle and
            get confirmation the latest time within which a vehicle can be availed to a client and hence service
            delivered is 48 hrs/2 days.
          </p>
        </div>
        <div className='condition card'>
          <h2>11.0 Liability</h2>
          <p>
            <strong>11.1</strong> All City Drive vehicles once rented shall not be used for activities that are
            prohibited by the laws of Zambia, and the company shall not accept any responsibility on any of its vehicles
            used for such activities. All vehicles not returned on time within 24 hrs after the end of hire and without
            prior communication of the extension of the rental period will be declared stolen.
            <br />
            <br />
            <strong>11.2</strong> All clients are required to read through both the vehicle Rental Contract terms. The
            acceptance of our offer/service provision on the part of the client and the signing of the vehicle rental
            contract will be full acceptance of our vehicle rental terms and conditions as outlined on the vehicle
            rental contract form. All trading conditions and the vehicle rental contract terms and conditions shall be
            construed and enforced according to the laws of the Republic of Zambia.
          </p>
        </div>
      </div>
    </>
  );
};
