// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';

export const CarDetail = () => {
  return (
    <div className="car-detail-page">
      <div className="car-images">
        <img src="car1.jpg" alt="Car 1" className="car-image" />
        <img src="car1-interior.jpg" alt="Car 1 Interior" className="car-image" />
      </div>
      <div className="car-details">
        <h2 className="car-title">Luxury Sedan</h2>
        <p className="car-description lead">
          Enjoy a comfortable and stylish ride in our luxury sedans.
          Perfect for business trips or special occasions.
        </p>
        <ul className="car-features">
          <li>5-passenger seating</li>
          <li>Leather interior</li>
          <li>GPS navigation system</li>
          <li>Bluetooth connectivity</li>
          <li>Backup camera</li>
        </ul>
        <div className="car-price">
          <span className="price-label">Price per day:</span>
          <span className="price-value">K800</span>
        </div>
        <button className="rent-btn">Rent Now</button>
      </div>
    </div>
  );
};
