import axios from "axios";
import jwt_decode from 'jwt-decode';

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setToken } from "../../utils/localStorage";

const baseURL = process.env.REACT_APP_API_URL;

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;

export interface AuthState {
  loading: boolean;
  userInfo: any;
  userToken: any;
  error: any;
  success: boolean;
}

const initialState: AuthState = {
  loading: false,
  userInfo: null,
  userToken: userToken,
  error: null,
  success: false,
}

export const loginUserAsync = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/auth/login`,
        { username, password },
        config
      );
      setToken(data.access_token);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUserAsync = createAsyncThunk(
  'auth/register',
  async ({ username, password, phone, firstname, lastname }: { username: string; password: string, phone: string, firstname: string, lastname: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      await axios.post(
        `${baseURL}/auth/signup`,
        { username, password, phone, firstname, lastname },
        config
      );
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyUserAsync = createAsyncThunk(
  'auth/verify',
  async ({ username, otp }: { username: string; otp: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const validate = await axios.post(
        `${baseURL}/otp/validate`,
        { username, otp },
        config
      );
      return validate.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changeUserPasswordAsync = createAsyncThunk(
  'auth/changePassword',
  async ({ username, currentPassword, newPassword }: { username: string;  currentPassword: string, newPassword: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/auth/change-password`,
        { username, currentPassword, newPassword },
        config
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken');
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.userToken = payload.access_token;
        state.userInfo = jwt_decode(payload.access_token);
        state.success = true;
      })
      .addCase(loginUserAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(registerUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUserAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(registerUserAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(changeUserPasswordAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeUserPasswordAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(changeUserPasswordAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { logout } = authSlice.actions;

export const selectUserInfo = (state: RootState) => state.auth.userInfo;
export const selectUserToken = (state: RootState) => state.auth.userToken;

export default authSlice.reducer;
