// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import axios from "axios";
import { getToken } from "../../utils/localStorage";

const baseURL = process.env.REACT_APP_API_URL;

const authToken = getToken();

export const getUsers = () => {
  const config = {
    headers: {
      'Authorization': `Bearer ${authToken ? authToken : null}`,
  },
}

  return axios.get<any[]>(`${baseURL}/users`, config);
}

export const getUser = (userId: number) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${authToken ? authToken : null}`,
  },
}
  return axios.get<any>(`${baseURL}/users/id/${userId}`, config);

}

export const editUser = () => {

}

export const deleteProfile = () => {

}