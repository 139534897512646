// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import React, { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { Outlet, Navigate } from "react-router-dom";
import { getToken, removeToken } from "../../utils/localStorage";
import { useAppDispatch } from "../../app/hooks";
import { clearUser } from "../../app/user/userSlice";

type AuthProps = {
  allowedRole: string;
};

interface IUT {
  username: string;
  role: string;
  sub: string;
  exp: number;
}

const Protected = ({ allowedRole }: AuthProps) => {
  const dispatch = useAppDispatch();

  const lsUserToken = getToken();
  const [isValidToken, setIsValidToken] = useState(true);

  useEffect(() => {
    const checkTokenValidity = () => {
      try {
        if (lsUserToken !== null) {
          const userToken = jwt_decode(lsUserToken) as IUT;
          // Check if the token has expired
          if (Date.now() >= userToken.exp * 1000) {
            // throw new Error("Token has expired");
            removeToken();
            dispatch(clearUser());
            setIsValidToken(false);
          }
        } else {
          // throw new Error("Token not found");
          dispatch(clearUser());
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Error:", error);
        removeToken();
        dispatch(clearUser());
        setIsValidToken(false);
      }
    };

    checkTokenValidity();
  }, [lsUserToken, dispatch]);

  if (!isValidToken) {
    return <Navigate to="/login" replace />;
  }

  const userToken = lsUserToken ? jwt_decode(lsUserToken) as IUT : null;
  if (userToken && allowedRole === userToken.role) {
    return <Outlet />;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

export default Protected;
