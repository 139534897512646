import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import vehicleReducer from './vehicle/vehicleSlice';
import vehicleTypeReducer from './vehicle-type/vehicle-typeSlice';
import bookingReducer from './booking/bookingSlice';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import paymentReducer from './payment/paymentSlice';
import tourPackageReducer from './tour-package/tourPackageSlice';
import tourBookingReducer from './tour-booking/tourBookingSlice';

export const store = configureStore({
  reducer: {
    vehicle: vehicleReducer,
    vehicleType: vehicleTypeReducer,
    booking: bookingReducer,
    auth: authReducer,
    user: userReducer,
    payment: paymentReducer,
    tourPackage: tourPackageReducer,
    tourBooking: tourBookingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
