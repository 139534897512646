// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { ClipLoader } from "react-spinners";

export const Processing = () => {
  return (
    <>
      <div className='col-md-5 text-center mx-auto py-4'>
        <ClipLoader size={55} color='#e81f29' className='mx-auto red-txt' />
        <h3 className='text-muted'>Processing...</h3>
      </div>
    </>
  );
};
