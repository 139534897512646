// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';
import { Link } from 'react-router-dom';
import comp from '../../images/car-rental-close.png';

export const About = () => {
  return (
    <>
      <section className='about-section'>
        <div className='container'>
          <div className='row'>
            <div className='about-img col-md-5'>
              <img src={comp} className='img-fluid' alt='' />
            </div>
            <div className='col-md-6'>
              <h2 className='section-heading text-center'>About Us</h2>
              <p className='section-description'>
                We believe in changing lives and moving society forward. City Drive is a mobility solutions company with
                a workforce which is passionate about Innovation, integrity, Hard work and forward-looking. We deliver a
                unique customer experience through various transport solutions including car rental, Taxi and
                Courier/Delivery. We are one of Zambia's leading mobility solutions firms with over 14 years of industry
                experience serving customers in various Market segments and industries as well as international visitors
                coming from outside Zambia. We currently operate in Zambia, Namibia and Mozambique.
              </p>
              <div className='row justify-content-evenly gap-4 py-4'>
                <div className=' col-md-5'>
                  <div className='card-body'>
                    <h3 className='card-title'>Our Mission</h3>
                    <p className='card-text section-description'>
                      To facilitate and enable the movement of people and goods in a cost-effective and reliable manner.
                    </p>
                  </div>
                </div>
                <div className=' col-md-5'>
                  <div className='card-body'>
                    <h3 className='card-title'>Our Vision</h3>
                    <p className='card-text section-description'>
                      To become the go-to platform for affordable and reliable mobility solutions in Zambia and Africa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className='col-md-7 mx-auto'>
            <i className=''>
              Explore our website to learn more about our car rental services. Contact Us if you have any questions or
              need assistance. We look forward to serving you and making your journey a memorable one.
            </i>
            <div className='text-center mx-auto py-4'>
              <Link to='/contact' className='btn btn-lg btn-r'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
