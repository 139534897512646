// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { getUserAsync } from '../../../app/user/userSlice';

import jwtDecode from 'jwt-decode';
import { IDecodedUser } from '../../../interfaces/user';
import { getToken } from '../../../utils/localStorage';

import avatar from '../../../images/user.png';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { UserProfile } from './UserProfile';
import { Overview } from './Overview';
import { QuotationHistory } from './QuotationHistory';
import { UserQuotePreview } from './UserQuotePreview';
import { CarRentalHistory } from './CarRentalHistory';
import { RentalDetails } from './RentalDetails';

export const Dashboard = () => {
  const [activeLink, setActiveLink] = useState('Dashboard');
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const { section } = useParams();

  const { userInfo } = useAppSelector((state: RootState) => state.user);

  const tk = getToken();
  let d: IDecodedUser;
  let uid = 0;
  if (tk !== null) {
    d = jwtDecode(tk);
    uid = d.sub;
  }

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    const quotationId = link.startsWith('quotations/') ? link.split('/')[1] : null;
    if (quotationId !== null) {
      history(`/dashboard/${link}`);
    } else {
      history(`/dashboard/${link.toLowerCase().replace(/\s/g, '-')}`);
    }
  };

  useEffect(() => {
    dispatch(getUserAsync(uid));
  }, [dispatch, uid]);

  return (
    <div className='user-dashboard-page'>
      <div className='row gap-3 px-4'>
        <div className='sidebar-cont col-md-3 px-0 shadow'>
          <div className='card'>
            <div className='card-body'>
              <div className='user-detail-card'>
                <div className='user-img'>
                  <img src={avatar} className='img-fluid' alt={userInfo?.firstname} />
                </div>
                <div className='user-details'>
                  <h5 className='card-title'>
                    {userInfo?.firstname} {userInfo?.lastname}
                  </h5>
                  <h6 className='card-subtitle mb-2 text-body-secondary'></h6>
                  <p className='card-text'>
                    <FaEnvelope /> <small className='user-email-sm'>{userInfo?.username}</small>
                    <br />
                    <FaPhoneAlt /> <small className='user-email-sm'>{userInfo?.phone}</small>
                  </p>
                </div>
              </div>
              <hr />
              <div className='sidebar-links-cont py-0'>
                <div
                  onClick={() => handleLinkClick('Dashboard')}
                  className={`dash-link ${activeLink === 'Dashboard' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>Dashboard</div>
                </div>
                <div
                  onClick={() => handleLinkClick('Quotations')}
                  className={`dash-link ${activeLink === 'Quotations' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>Car Rental Quotations</div>
                </div>

                <div
                  onClick={() => handleLinkClick('Rentals')}
                  className={`dash-link ${activeLink === 'Rentals' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>Car Rental Bookings</div>
                </div>

                <div
                  onClick={() => handleLinkClick('Profile')}
                  className={`dash-link ${activeLink === 'Profile' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>My Profile</div>
                </div>

                <div
                  onClick={() => handleLinkClick('Password & Security')}
                  className={`dash-link ${activeLink === 'Password & Security' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>Password & Security</div>
                </div>

                <hr />

                <div
                  onClick={() => handleLinkClick('Help')}
                  className={`dash-link ${activeLink === 'Help' ? 'dash-active' : ''}`}
                >
                  <div className='py-2 px-3 my-2'>Help & Support</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='sidebar-content col-md-8 py-4'>
          {section === 'dashboard' && (
            <div>
              <Overview />
            </div>
          )}
          {section === 'profile' && (
            <div>
              <UserProfile />
            </div>
          )}
          {section?.startsWith('quotations/') && (
            <div>
              <UserQuotePreview />
            </div>
          )}
          {section === 'quotations' && (
            <div>
              <QuotationHistory />
            </div>
          )}
          
          {section?.startsWith('rentals/') && (
            <div>
              <RentalDetails />
            </div>
          )}
          {section === 'rentals' && (
            <div>
              <CarRentalHistory />
            </div>
          )}
        </div>
      </div>

      <hr />
    </div>
  );
};
