// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React from 'react';

import { MDBCard, MDBCardBody, MDBTypography } from 'mdb-react-ui-kit';

export const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Martin Maribo',
      country: 'Spain',
      comment:
        'We have been very satisfied with your services and also the car you gave us. All papers to take the vehicle into Zimbabwe was in order. And the car served us so well. We owe you thanks for being so flexible with regards to adjusting time and place for pickup with very short notice, and even deducting the fee for delivery to Livingstone and the reduced number of days.',
    },
    {
      id: 2,
      name: 'Larry Barham',
      comment:
        'I will be coming back to Zambia on a regular basis for research and will be needing a vehicle (or two) for extended hire. My experience with City Drive has been good and in the past I have used other car rental companies of which I had a bad experience with and they are certainly off my list. I will now gladly add City Drive to the mix. I will be in touch early next year as I start planning my research for next July-August.',
      country: 'England',
    },
    {
      id: 3,
      name: 'Shamma Juneja',
      country: 'England',
      comment:
        'Thank you for such a kind email. This trip had certainly been one to remember as it has been very eventful but has totally been worth it. Thank you for being such a great company with wonderful staff. It was lovely meeting you all, you are all so friendly and made us feel right at home.',
    },
    {
      id: 4,
      name: 'Kerr',
      comment:
        'I would like to accept your offer and secure the booking right away. Just for your information, I contacted 5 car hire companies and you were the most efficient and helpful',
      country: 'Zambia',
    },
  ];

  return (
    <div className='container-fluid  shadow'>
      <h2 className='page-heading py-3'>Testimonials</h2>
      <div className='testimonials-container'>
        {testimonials.map((testimonial) => (
          <MDBCard className='testimonial col-md-4' key={testimonial.id}>
            <MDBCardBody>
              <MDBTypography blockquote className='mb-0'>
                <p>{testimonial.comment}</p>
                <footer className='blockquote-footer'>
                  <strong>{testimonial.name}</strong> from{' '}
                  <cite title='Source Title'>
                    <strong>{testimonial.country}</strong>
                  </cite>
                </footer>
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        ))}
      </div>
    </div>
  );
};
