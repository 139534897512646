// Copyright (C) Lembani Sakala - All rights reserved.
// Lembani Sakala.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { getUserAsync } from '../../../app/user/userSlice';

import jwtDecode from 'jwt-decode';
import { IDecodedUser } from '../../../interfaces/user';
import { getToken } from '../../../utils/localStorage';
import { formatDateTime } from '../../../utils/datetimeFomatter';

export const CarRentalHistory = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.user);

  const tk = getToken();
  let d: IDecodedUser;
  let uid = 0;
  if (tk !== null) {
    d = jwtDecode(tk);
    uid = d.sub;
  }

  useEffect(() => {
    dispatch(getUserAsync(uid));
  }, [dispatch, uid]);

  return (
    <>
      <div className='py-3'>
        <h1 className='heading text-center'>Car Rental Bookings</h1>
        <div className='mx-auto shadow'>
          <table className='table table-hover table-striped table-responsive'>
            <thead>
              <tr>
                <th>Booking#</th>
                <th>Status</th>
                <th>Location</th>
                <th>Rental Date</th>
                <th>Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userInfo?.bookings.length === 0 ? (
                <tr className='col-md-4 mx-auto text-center'>
                  <h4>No car rental history at the moment</h4>
                </tr>
              ) : (
                userInfo?.bookings
                  .filter((quote?: any) => {
                    // Filter for "Paid Transactions" only
                    return (
                      quote?.status === 'Transaction Paid' ||
                      (quote?.payment.length > 0 && quote?.payment[0]?.status === 'Transaction Paid')
                    );
                  })
                  .sort((a: any, b: any) => {
                    // Sort by the createdAt date in descending order
                    const aCreatedAt = new Date(a.createdAt).getTime();
                    const bCreatedAt = new Date(b.createdAt).getTime();
                    return bCreatedAt - aCreatedAt;
                  })
                  .map((quote: any, index: number) => {
                    const startDateTime = new Date(quote?.startDate);
                    const { formattedDate: formattedStartDate } = formatDateTime(startDateTime);
                    const endDateTime = new Date(quote?.endDate);
                    const { formattedDate: formattedEndDate } = formatDateTime(endDateTime);

                    const durationText = quote?.duration === 1 ? 'day' : 'days';

                    return (
                      <tr key={index}>
                        <td>{quote?.bookingNumber}</td>
                        <td><span className="badge rounded-pill bg-success p-2">{quote?.payment[0]?.status}</span></td>
                        <td>
                          {quote?.pickUpTown === quote?.drivingToTown ? (
                            <>Within {quote?.pickUpTown}</>
                          ) : (
                            <>{quote?.pickUpTown} &mdash; {quote?.drivingToTown}</>
                          )}
                        </td>
                        <td>
                          {formattedStartDate} &mdash; {formattedEndDate}
                        </td>
                        <td>{quote?.duration} {durationText}</td>
                        <td>
                          <Link
                            to={`/dashboard/quotations/${quote?.id}`}
                            className='btn btn-sm text-white btn-success mx-1'
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
